import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log("🚀 ~ file: auth.guard.ts ~ line 19 ~ AuthGuard ~ RouterStateSnapshot", state);
    return this.checkLoggedIn();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log("🚀 ~ file: auth.guard.ts ~ line 23 ~ AuthGuard ~ ActivatedRouteSnapshot", state);
    return this.checkLoggedIn();
  }

  checkLoggedIn() {
    // return true;
    const parsedJwtToken = this.authService.token;
    // console.log("🚀 ~ file: auth.guard.ts ~ line 27 ~ AuthGuard ~ checkLoggedIn ~ token", parsedJwtToken);
    // if (parsedJwtToken) {
    //   // const parsedJwtToken: ProviderJwtToken = JwtTokenParser(token);
    //   if (parsedJwtToken.defaultUserType == "") {
    //     // create profile
    //     this.router.navigate(['/login/add-user']);
    //     //return false;
    //     console.log();
    //   } else if (parsedJwtToken.defaultUserType != "" &&
    //     parsedJwtToken.userType != "" &&
    //     parsedJwtToken.defaultClinic == "") {
    //     //switch clinic
    //     //return false;
    //     console.log();
    //   } else if (parsedJwtToken.userType != "" &&
    //     parsedJwtToken.defaultUserType == "") {
    //     // create profile
    //     this.router.navigate(['/login/add-user']);
    //     //return false;
    //     console.log();
    //   }
    //   else if (parsedJwtToken.defaultUserType != "" &&
    //     parsedJwtToken.defaultClinic == "" &&
    //     parsedJwtToken.userType == "") {
    //     //create clinic
    //     this.router.navigate(['/login/create-clinic']);
    //     //return false;
    //     console.log();
    //   }
    //   else if (parsedJwtToken.defaultUserType != "" &&
    //     parsedJwtToken.defaultClinic != "" &&
    //     parsedJwtToken.userType != "") {
    //     //home
    //     this.router.navigate(['/dashboard']);
    //     //return false;
    //     console.log();
    //   }
    //   else {
    //     this.router.navigate(['/login']);
    //     //return false;
    //     console.log();
    //   }
    // } else {
    //   this.router.navigate(['/login']);
    //   //return false;
    //   console.log();
    // }
    return true;
  }
}
