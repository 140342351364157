<nb-layout windowMode>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
    <ng-content select="nb-menu"></ng-content>
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle m-4 d-flex justify-content-center">
      <nb-icon class="menu-icon fa fa-arrows-h">
      </nb-icon>
    </a>
  </nb-sidebar>
  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
  <nb-layout-footer fixed>
    <vaas-footer></vaas-footer>
  </nb-layout-footer>
</nb-layout>