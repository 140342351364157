import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { getTokenAfterCheck, removeToken } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private router: Router) { }
  get token() {
    return getTokenAfterCheck();
  }

  logout() {
    removeToken();
    this.router.navigate(['/login']);
  }
}
