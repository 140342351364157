import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

import { getToken } from '..';
import { environment } from '../../../environments/environment';
import { ApiReturn, CliniqallyUserAppBaseRestApiJson, ContentType, RestMethod, StatusCode } from '../standard';
import { ToastService } from '../toastservice/toastservice.service';

declare const $: any;


const baseUrl: string = environment.baseUrl;
@Injectable({
  providedIn: 'root',
})
export class CommunicatorService {
  constructor(
    private http: HttpClient,
    private toasterService: ToastService,
    private authService: AuthService,
  ) { }

  ApiRunner(apiJson: CliniqallyUserAppBaseRestApiJson): Observable<ApiReturn> {
    let headers = new HttpHeaders();
    // console.log('upload form data',!apiJson.uploadFormData);
    // if (!apiJson.uploadFormData) {
    //   headers = headers.set('Content-Type', ContentType.formData);
    // }
    if (apiJson.contentType === ContentType.json) {
      headers = headers.set('Content-Type', apiJson.contentType);
    }
    if (apiJson.authentication) {
      headers = headers.set('Authorization', `Bearer ${getToken()}`);
    }
    headers = headers.set('Bypass-Tunnel-Reminder', "true");
    let url = `${baseUrl}`;
    url = url + apiJson.url;
    if (apiJson.pathParameters) {
      url = `${url}/${apiJson.pathParameters}`;
    }
    if (apiJson.url == '' && apiJson.queryParameters) {
      url = `${baseUrl}?${apiJson.queryParameters}`;
    } else if (apiJson.url != '' && apiJson.queryParameters) {
      url = `${url}?${apiJson.queryParameters}`;
    }

    console.log('communicator calling', url);
    if (apiJson.restMethod === RestMethod.get) {
      //todo:refer if needed. to be implemented in another way.
      // let params = new HttpParams();
      // if (apiJson.queryParams) {
      //   if (apiJson.queryParams.type) params = params.append('type', String(apiJson.queryParams.type));
      //   if (apiJson.queryParams.start_date) params = params.append('start_date', String(apiJson.queryParams.start_date));
      //   if (apiJson.queryParams.end_date) params = params.append('end_date', String(apiJson.queryParams.end_date));
      // }
      // const apiReturn = this.http
      //   .get(url, { headers: headers, params: params })
      //   .do(console.log)
      //   .pipe(catchError(this.errorHandler));
      return this.http.get(url, { headers })
        .pipe(
          map(response => {
            const apiReturn = response as ApiReturn;
            return apiReturn;
          },
            catchError(this.errorHandler)),
        );
    }
    else if (apiJson.restMethod === RestMethod.post) {
      let uploadPayload: any;
      if (apiJson.uploadFormData) {
        console.log("its a form data");
        uploadPayload = apiJson.uploadFormData;
        uploadPayload.forEach((element: any) => {
          console.log('form element', { element });
        });
      } else {
        console.log("its a json");
        uploadPayload = Object.assign({}, apiJson);
        delete uploadPayload.restMethod;
        delete uploadPayload.url;
        delete uploadPayload.authentication;
      }
      const apiReturn = this.http
        .post<ApiReturn>(url, (apiJson.uploadFormData) ? apiJson.uploadFormData : uploadPayload, { headers })
        .pipe(catchError(this.errorHandler)); // catch error
      return apiReturn;
    } else if (apiJson.restMethod === RestMethod.patch) {
      let uploadPayload: any;
      if (apiJson.uploadFormData) {
        console.log("its a form data");
        uploadPayload = apiJson.uploadFormData;
        uploadPayload.forEach((element: any) => {
          console.log({ element });
        });
      } else {
        console.log("its a json");
        uploadPayload = Object.assign({}, apiJson);
        delete uploadPayload.restMethod;
        delete uploadPayload.url;
        delete uploadPayload.authentication;
      }
      const apiReturn = this.http
        .patch<ApiReturn>(url, apiJson.contentType === ContentType.formData ? apiJson.uploadFormData : uploadPayload, { headers: headers })
        .pipe(catchError(this.errorHandler));
      return apiReturn;
    }
    else if (apiJson.restMethod === RestMethod.put) {
      if (apiJson.uploadFormData) {
        console.log("its a form data");
        apiJson.uploadFormData.forEach((element: any) => {
          console.log({ element });
        });
      } else {
        console.log("its a json");
      }
      const apiReturn = this.http
        .put<ApiReturn>(url, (apiJson.uploadFormData) ? apiJson.uploadFormData : apiJson, { headers })
        .pipe(catchError(this.errorHandler));
      return apiReturn;
      // else if (apiJson.restMethod === RestMethod.put) {
      //   const apiReturn = this.http
      //     .put<ApiReturn>(url, apiJson, { headers })
      //     .pipe(catchError(this.errorHandler));
      //   return apiReturn;
    }
    else if (apiJson.restMethod === RestMethod.delete) {
      console.log("delete", url);
      const apiReturn = this.http
        .delete<ApiReturn>(url, { headers })
        .pipe(catchError(this.errorHandler));
      return apiReturn;
    }
    throw new Error('Shouldn\'t be reachable');
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred: ' + error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      console.log('Api returned', JSON.stringify(error.error));
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error(error.error));
  }

  responseParser(apiReturn: ApiReturn) {
    if (apiReturn) {
      if (apiReturn.status) {
        return true;
      } else if (apiReturn.StatusCode === StatusCode.NotFound) {
        this.toasterService.showNotFoundInApi();
        return false;
      } else if (apiReturn.StatusCode === StatusCode.InvalidLoginError) {
        this.toasterService.showInvalidLogin();
        return false;
      } else {
        if (apiReturn.licenseExpired) {
          this.toasterService.showError("Your clinic license has expired. Please contact your administrator to renew the license");
          $('#licenseExpiredPageId').modal('show');
        }
        if (apiReturn.reauth) {
          // todo: possible token expired, re login, or fix with refresh token and retry.
          this.toasterService.showError("Your login has expired. Please login again");
          this.authService.logout();
        }
        this.toasterService.showError(apiReturn.message);
        return apiReturn.status;
      }
    } else {
      this.toasterService.showError('Something went wrong');
      return false;
    }
  }
}
