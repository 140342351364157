import { EventEmitter, Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

import { getToken } from '..';

@Injectable({
  providedIn: 'root'
})
export class VaasSocketService {
  token = getToken();
  socket = io(environment.socketUrl, {
    transports: ['websocket'],
    query: {
      'authorization': encodeURIComponent(this.token ?? '')
    }
  });

  constructor() { }

  connect() {
    const returnEvent = new EventEmitter<string>();
    if (environment.socket) {
      this.socket.on("connect", () => {
        returnEvent.emit(
          "Messaging service is connected"
        ); // true
      });

      this.socket.on("disconnect", () => {
        returnEvent.emit(
          "Messaging service is disconnected"
        );
      });
    } else {
      returnEvent.emit(
        "Messaging service is not supported"
      );
    }
    return returnEvent;
  }

  joinRoom(roomId: string) {
    const returnEvent = new EventEmitter<object>();
    if (environment.socket) {
      this.socket.on(roomId, function (message: object) {
        // console.log('[message]: ' + JSON.stringify(message));
        returnEvent.emit(
          message
        );
      });
    } else {
      // console.log("Messaging is off");
      return false;
    }
    return returnEvent;
  }

  disconnect(): void {
    if (environment.socket) {
      this.socket.disconnect();
    } else {
      console.log("Messaging is off");
    }
  }

  // sendMessage(msg: string) {
  //   if (environment.socket) {
  //     this.socket.emit('message', msg);
  //   } else {
  //     console.log("Messaging is off");
  //   }
  // }
}
