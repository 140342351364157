export const environment = {
  production: false,
  environment: 'beta',
  sentry: true,
  baseUrl: "https://betacliniqallyapi.vaas.tech/",
  // baseUrl: "http://localhost:4000/",
  // socketUrl: "http://localhost:4001/provider",
  socketUrl: "https://betacliniqallystreams.vaas.tech/provider",
  tracesSampleRate: 0.25,
  socket: true,
  firebase: {
    apiKey: "AIzaSyDFCJEoz378PhqUPEJv-2ZssSBsohI5slg",
    authDomain: "cliniqally-291e5.firebaseapp.com",
    databaseURL: "https://cliniqally-291e5-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "cliniqally-291e5",
    storageBucket: "cliniqally-291e5.appspot.com",
    messagingSenderId: "599683144204",
    appId: "1:599683144204:web:7e72d2c14921f6cd436c29",
    measurementId: "G-J29HQ53YBV"
  }
};
