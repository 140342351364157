import * as moment from 'moment';

import { getTimeZone, getTimeZoneToDisplay } from '..';
import { TimingsType } from '../standard';
import { convertFromTimezoneToTimezone, convertFromUTCToTimezone, convertToTimeZoneWithFormat } from './timezoneConverter';

export const detailedDateFormat = 'ddd, Do MMM YYYY hh:mm A Z';
export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultTimeFormat = 'HH:mm';
export const getCurrentDateTimeFromTimings = (timings: TimingsType) => {
  const currentTimezone = getTimeZone();
  if (currentTimezone) {
    const today = moment().utc();
    const timingDay = today.day(timings.day);
    return {
      "day": timings.day, "timing": timings.timing.map(timing => {
        const startTimeArray = timing.start_time.split(":");
        const endTimeArray = timing.end_time.split(":");
        const formattedStartTime = getLocalDateTimeOnMomentFromDateObject(timingDay, startTimeArray, currentTimezone, "h:mm a");
        const formattedEndTime = getLocalDateTimeOnMomentFromDateObject(timingDay, endTimeArray, currentTimezone, "h:mm a");
        return { ...timing, 'formattedStartTime': formattedStartTime, 'formattedEndTime': formattedEndTime };
      })
    };
  } else {
    return timings;
  }
};
export function getLocalDateTimeFromDateTime(time: string, date?: string) {
  const currentTimezone = getTimeZone();
  if (currentTimezone) {
    const timeArray = time.split(":");
    let today;
    if (date && date.length > 0) {
      today = moment(date).utc();
    } else {
      today = moment().utc();
    }
    return getLocalDateTimeOnMomentFromDateObject(today, timeArray, currentTimezone, "h:mm a DD-MM-YYYY Z");
  } else {
    return "";
  }
}
export function getLocalDateFromDateTime(time: string, date?: string) {
  const currentTimezone = getTimeZone();
  if (currentTimezone) {
    const timeArray = time.split(":");
    let today;
    if (date && date.length > 0) {
      today = moment(date).utc();
    } else {
      today = moment().utc();
    }
    return getLocalDateTimeOnMomentFromDateObject(today, timeArray, currentTimezone, "DD-MM-YYYY");
  } else {
    return "";
  }
}
export function getTimesOfDay(time: string, date?: string) {
  const currentTimezone = getTimeZone();
  if (currentTimezone) {
    const timeArray = time.split(":");
    let today;
    if (date && date.length > 0) {
      today = moment(date).utc();
    } else {
      today = moment().utc();
    }
    const currentTime = getLocalDateTimeOnMomentFromDateObject(today, timeArray, currentTimezone, "HH:mm");
    const currentTimeArray = currentTime.split(":");
    const currentHour = Number(currentTimeArray[0]);
    if (currentHour >= 0 && currentHour < 6) {
      return "Early morning";
    } else if (currentHour >= 6 && currentHour < 12) {
      return "Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Afternoon";
    } else if (currentHour >= 18 && currentHour < 24) {
      return "Evening";
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function getLocalDateTimeOnMomentFromDateObject(incomingDate: moment.Moment, timeArray: string[], timezone: string, format: string) {
  const hour = +timeArray[0];
  const minute = +timeArray[1];
  const toReturn = moment(incomingDate).hour(hour).minute(minute).second(0);
  const formattedDate = convertToTimeZoneWithFormat(toReturn, timezone, format);
  return formattedDate;
}

export function getGmtDate(incomingDate?: string | moment.Moment) {
  const returnDate = getMomentObjectWithTimeZone(incomingDate).format('YYYY-MM-DD');
  return returnDate;
}

export function getMomentObjectWithTimeZone(incomingDate?: string | moment.Moment) {
  const timeZone = getTimeZone();
  if (incomingDate) {
    // console.log("🚀 ~ file: timeFunctions.ts:110 ~ getMomentObjectWithTimeZone ~ timeZone:", timeZone);
    if (timeZone) {
      const incomingDateMoment = moment(incomingDate).utcOffset(timeZone, true);
      // console.log("🚀 ~ file: timeFunctions.ts:111 ~ getMomentObjectWithTimeZone ~ incomingDateMoment:", getFormattedDateForDashboard(incomingDateMoment));
      return convertFromUTCToTimezone(incomingDateMoment, timeZone);
    } else {
      return moment(incomingDate);
    }
  } else {
    if (timeZone) {
      return convertFromUTCToTimezone(moment().tz(timeZone), timeZone);
    } else {
      return moment();
    }
  }
}

export function getGmtTime(incomingDate: string | moment.Moment) {
  const returnTime = getMomentObjectWithTimeZone(incomingDate).format('HH:mm');
  return returnTime;
}

export function getFormattedDateForDashboard(incomingDate: any) {
  const returnDate = moment(incomingDate).format('ddd, Do MMM YYYY');
  return returnDate;
}

export function getFormattedDates(incomingDate: any, format: string) {
  const returnDate = moment(incomingDate).format(format);
  return returnDate;
}

export function getFormattedLocalDateForDashboard(incomingDate: any) {
  const currentTimezone = getTimeZone();
  let returnDate;
  if (currentTimezone) {
    returnDate = moment(incomingDate).utcOffset(currentTimezone).format('ddd, Do MMM YYYY');
  } else {
    returnDate = moment(incomingDate).format('ddd, Do MMM YYYY');
  }
  return returnDate;
}

export function selectedDateOnGMTTimezone(dateInput: string, timeInput: string) {
  let currentTimezone = getTimeZoneToDisplay();
  currentTimezone = currentTimezone || "+00:00";
  const timeArray = timeInput.split(":");
  const returnDate = moment(dateInput).hour(Number(timeArray[0])).minute(Number(timeArray[1])).second(0).utcOffset(currentTimezone, true);
  const utcMoment = convertFromUTCToTimezone(returnDate, "UTC");
  const date = utcMoment.format(defaultDateFormat);
  const time = utcMoment.format(defaultTimeFormat);
  return { date, time };
}

export function selectedTimeOnLocalTimezone(timeInput: string) {
  const timeArray = timeInput.split(":");
  const returnDate = moment().hour(Number(timeArray[0])).minute(Number(timeArray[1])).second(0).utcOffset("+00:00", true);
  const utcMoment = convertFromUTCToTimezone(returnDate, getTimeZone() || "UTC");
  const time = utcMoment.format(defaultTimeFormat);
  return time;
}

export function selectedTimeOnGMTTimezone(timeInput: string) {
  let currentTimezoneName = getTimeZoneToDisplay();
  currentTimezoneName = currentTimezoneName || "+00:00";
  let currentTimezone = getTimeZone();
  currentTimezone = currentTimezone || "UTC";
  const timeArray = timeInput.split(":");
  const returnDate = moment().hour(Number(timeArray[0])).minute(Number(timeArray[1])).second(0).utcOffset(currentTimezoneName, true);
  const utcMoment = convertFromTimezoneToTimezone(returnDate, currentTimezone, "UTC");
  const time = utcMoment.format(defaultTimeFormat);
  return time;
}

