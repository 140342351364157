import { Component, OnInit } from '@angular/core';
import { getApplicationReleaseDate, getApplicationVersion } from 'src/app/services';


@Component({
  selector: 'vaas-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
     <a href="https://cliniqally.com" target="_blank"> Created with ♥ by <b>Cliniqally</b> {{year}}</a>
    </span>
    <div class="socials">
      <p class="cliniqallyBlueColor">{{appVersion}}</p>
    </div>
  `,
})
export class FooterComponent implements OnInit {
  year = 2023;
  appVersion = `App version ${getApplicationVersion()} | Released on ${getApplicationReleaseDate()}`;
  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }
}
