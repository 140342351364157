import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { addStaffInterface, consumerIdInterface, getPatientDataInterface } from '../standard';
import { profileCompletionStatusEnum } from './helper';

@Injectable({
  providedIn: 'root',
})
export class MessageBusService {
  // private UserData = new Subject<any>();
  // private RefreshPageForNotification = new Subject<any>();
  // private storageSub = new Subject<String>();
  private profileCompletionStatus = new ReplaySubject<profileCompletionStatusEnum>();
  private AddStaffStatus = new ReplaySubject<addStaffInterface>();
  private consumerIdStatus = new ReplaySubject<consumerIdInterface>();
  private loaderLottie = new ReplaySubject<boolean>();
  private reloadPage = new ReplaySubject<boolean>();
  private reloadPatientListing = new ReplaySubject<boolean>();
  private clinicSwitched = new ReplaySubject<boolean>();
  private getPatientData = new ReplaySubject<getPatientDataInterface>();
  private getConsumerIdEditPatient = new Subject<string>();
  private prescriptionId = new ReplaySubject<string>();
  private reloadPageAssignStaff = new ReplaySubject<boolean>();
  private reloadMyProfile = new ReplaySubject<boolean>();

  constructor() { /* TODO document why this constructor is empty */ }

  // profile completion navigation
  triggerProfileCompletionStatusChanged(status: profileCompletionStatusEnum) {
    this.profileCompletionStatus.next(status);
  }

  profileCompletionStatusChanged(): Observable<profileCompletionStatusEnum> {
    return this.profileCompletionStatus.asObservable();
  }

  // add doctor staff
  triggerAddStaffStatusChanged(status: addStaffInterface) {
    this.AddStaffStatus.next(status);
  }

  AddStaffStatusChanged(): Observable<addStaffInterface> {
    return this.AddStaffStatus.asObservable();
  }

  // consumer id
  triggerConsumerIdData(status: consumerIdInterface) {
    this.consumerIdStatus.next(status);
  }

  consumerIdData(): Observable<consumerIdInterface> {
    return this.consumerIdStatus.asObservable();
  }

  // trigger lottie
  triggerPageLoader(status: boolean) {
    this.loaderLottie.next(status);
  }

  pageLoaderStatusChanged(): Observable<boolean> {
    return this.loaderLottie.asObservable();
  }

  // page refresh
  triggerPageRefresh(status: boolean) {
    this.reloadPage.next(status);
  }

  pageRefreshStatusChanged(): Observable<boolean> {
    return this.reloadPage.asObservable();
  }

  // my profile refresh
  triggerPageRefreshMyProfile(status: boolean) {
    this.reloadMyProfile.next(status);
  }

  pageRefreshMyProfileStatusChanged(): Observable<boolean> {
    return this.reloadMyProfile.asObservable();
  }

  // patient listing refresh
  triggerPatientPageRefresh(status: boolean) {
    this.reloadPatientListing.next(status);
  }

  patientPageRefreshStatusChanged(): Observable<boolean> {
    return this.reloadPatientListing.asObservable();
  }

  // switch clinic trigger
  triggerSwitchClinicRefresh(status: boolean) {
    this.clinicSwitched.next(status);
  }

  switchClinicRefreshStatusChanged(): Observable<boolean> {
    return this.clinicSwitched.asObservable();
  }

  // requestRefreshPageForNotification() {
  //   this.RefreshPageForNotification.next();
  // }
  // refreshPageForNotification(): Observable<any> {
  //   return this.RefreshPageForNotification.asObservable();
  // }

  // setUserData(dataToBus: MessageData) {
  //   this.UserData.next(dataToBus);
  // }

  // clearUserData() {
  //   this.UserData.next();
  // }

  // getUserData(): Observable<MessageData> {
  //   return this.UserData.asObservable();
  // }


  // public watchStorage(): Observable<any> {
  //   return this.storageSub.asObservable();
  // }

  // public setItem(key: string, data: any) {
  //   localStorage.setItem(key, data);
  //   this.storageSub.next(key);
  // }

  // public getItem(key: string) {
  //   if (key in localStorage) {
  //     return localStorage.getItem(key);
  //   }
  //   return null;
  // }

  // public removeItem(key) {
  //   localStorage.removeItem(key);
  //   this.storageSub.next(key);
  // }

  // public resetLocalStorage() {
  //   localStorage.clear();
  // }


  triggerGetPatientData(status: getPatientDataInterface) {
    this.getPatientData.next(status);
  }

  GetPatientData(): Observable<getPatientDataInterface> {
    return this.getPatientData.asObservable();
  }

  // consumer id for edit patient
  triggerGetPatientIdData(consumerId: string) {
    this.getConsumerIdEditPatient.next(consumerId);
  }

  GetConsumerIdEditPatient(): Observable<string> {
    return this.getConsumerIdEditPatient.asObservable();
  }

  //  medical certificate common modal 
  triggerMedicalCertificateModal(status: string) {
    this.prescriptionId.next(status);
  }

  medicalCertificateModalStatusChanged(): Observable<string> {
    return this.prescriptionId.asObservable();
  }

  // page refresh assign staff
  triggerAssignStaff(status: boolean) {
    this.reloadPageAssignStaff.next(status);
  }

  assignStaffStatusChanged(): Observable<boolean> {
    return this.reloadPageAssignStaff.asObservable();
  }
}
