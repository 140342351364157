import { decodeText, userTypeEnum } from '../standard';

//todo: to fix this name of token for different env's
export const tokenName = "Dev-DoctorApp";
export interface ProviderJwtToken {
    userDetails: {
        firstName: string,
        lastName: string,
        countryCode: string,
        mobileNumber: string,
        email: string,
        cliniqallyId: string,
        timeZone: string,
        timeZoneName: string,
        profilePicture: string,
        degree: [],
        specialization: []
    },
    providerId: string,
    defaultClinic: string,
    defaultClinicName: string,
    isProfileVerified: boolean,
    expiryDate: string,
    defaultUserType: string,
    userType: userTypeEnum | string,
    permissions: any, // ! todo: add type as object here and update once roles and permissions come in
    defaultClinicCurrency: string;
    defaultClinicCurrencySymbol: string;
    defaultClinicCurrencySymbolAt: string;
    defaultClinicProfilePicture: string;
    memberSince: string;
}

export function JwtTokenParser(token: string): ProviderJwtTokenParsed | null {
    if (token && token.length > 0) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(''),
            );
            return JSON.parse(jsonPayload);
        } catch (ex) {
            return null;
        }
    } else {
        return null;
    }
}

export function getProviderId() {
    const token = getTokenAfterCheck();
    if (token) {
        return token.providerId;
    }
    return false;
}

export interface ProviderJwtTokenParsed extends ProviderJwtToken {
    iss: string | undefined;
    sub: string | undefined;
    aud: string | string[] | undefined;
    exp: number | undefined;
    nbf: number | undefined;
    iat: number | undefined;
    jti: string | undefined;
}

export function getCliniqallyIdProviderId() {
    const token = getTokenAfterCheck();
    if (token) {
        return token.userDetails.cliniqallyId;
    }
    return false;
}
export function getDefaultClinicName() {
    const token = getTokenAfterCheck();
    if (token) {
        return decodeText(token.defaultClinicName);
    }
    return false;
}

export function getProviderName() {
    const token = getTokenAfterCheck();
    if (token) {
        if (token.defaultUserType == userTypeEnum.doctor) {
            return `Dr. ${token.userDetails.firstName} ${token.userDetails.lastName}`;
        }
        return `${token.userDetails.firstName} ${token.userDetails.lastName}`;
    }
    return "";
}
export function setToken(token: string) {
    return localStorage.setItem(tokenName, token);
}

export function getTokenAfterCheck() {
    const token = getToken();
    if (token) {
        const tokenData = JwtTokenParser(token);
        if (tokenData?.exp) {
            if (Date.now() <= tokenData.exp * 1000) {
                return tokenData;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function getToken() {
    return localStorage.getItem(tokenName);
}

export function removeToken() {
    localStorage.clear();
    return localStorage.removeItem(tokenName);
}

function userTypeCheck(token: string, type: userTypeEnum) {
    if (token) {
        const jwtData = JwtTokenParser(token);
        return jwtData?.userType === type;
    } else {
        return false;
    }
}

export function isAdminUser() {
    const token = getToken();
    return token ? userTypeCheck(token, userTypeEnum.admin) : false;
}

export function getTimeZone() {
    const token = getTokenAfterCheck();
    if (token) {
        return token.userDetails.timeZoneName;
    }
    return false;
}

export function getTimeZoneToDisplay() {
    const token = getTokenAfterCheck();
    if (token) {
        return `${token.userDetails.timeZone} ${token.userDetails.timeZoneName}`;
    }
    return false;
}

export function getProfilePicture() {
    const token = getTokenAfterCheck();
    if (token) {
        return token.userDetails.profilePicture;
    }
    //todo: to change to local profile picture image
    return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6swZO8d-ZWsYcFSMuRaipYJhGTDvJVJ-dIg&usqp=CAU';
}

export function defaultClinicCurrency() {
    const token = getTokenAfterCheck();
    if (token) {
        return {
            defaultClinicCurrency: token.defaultClinicCurrency,
            defaultClinicCurrencySymbol: token.defaultClinicCurrencySymbol,
            defaultClinicCurrencySymbolAt: token.defaultClinicCurrencySymbolAt
        };
    }
    return false;
}
export function getCenterId() {
    const token = getTokenAfterCheck();
    if (token) {
        return token.defaultClinic;
    }
    return false;
}
export function getProviderDegree() {
    const token = getTokenAfterCheck();
    if (token) {
        if (token.defaultUserType == userTypeEnum.doctor) {
            return token.userDetails.degree;
        }
        return token.userDetails.degree;
    }
    return "";
}

export function getProviderSpecialization() {
    const token = getTokenAfterCheck();
    if (token) {
        if (token.defaultUserType == userTypeEnum.doctor) {
            return token.userDetails.specialization;
        }
        return token.userDetails.specialization;
    }
    return "";
}