import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ApiReturn,
  checkIfProfileImageExist,
  convertTimeForPublicPage,
  getClinicDetailsClinicOverViewJson
} from '../services';
import { CommunicatorService } from '../services/communicator/communicator.service';
import { MessageBusService } from '../services/messagebus/message-bus.service';
import { ToastService } from '../services/toastservice/toastservice.service';


@Component({
  selector: 'vaas-clinicoverview',
  templateUrl: './clinicoverview.component.html',
  styleUrls: ['./clinicoverview.component.scss']
})
export class ClinicoverviewComponent implements OnInit {
  loader = true;
  showNotFound = false;
  getClinicInfoDataList: any;
  clinicName: string;
  cliniqallyCenterId: string;
  emailId: string;
  clinicLocation: string;
  aboutClinic: string;
  clinicContact: number;
  countyCode: number;
  clinicCountry: string;
  clinicState: string;
  clinicCity: string;
  clinicAddress: string;
  clinicPincode: string;
  clinicSpecialities: any;
  clinicAverageRating: number;
  currentYear: number;
  days: any;
  downloadUrl = "https://urlgeni.us/clnqpatnt";
  DoctorDataList: any[];
  checkIfImageExist = checkIfProfileImageExist;
  totalDoctorCount = false;
  totalStaffCount = false;
  getClinicOverView: any;
  initialLat = 0;
  initialLng = 0;
  locationLoaded = false;
  markers: any[] = [];
  mapCenter: google.maps.LatLngLiteral = { lat: 28.7041, lng: 77.1025 };
  mapOptions: google.maps.MapOptions =
    {
      mapTypeId: 'hybrid',
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      zoom: 25,
      maxZoom: 25,
      minZoom: 8,
    };
  countryList: any;
  @ViewChild('search', { static: true })
  searchElementRef: ElementRef;
  showDiv = false;
  googleMapsUrl = "https://www.google.com/maps/search/?api=1&query=";
  uniqueUrl = "https://app.cliniqally.com/clinic/";
  constructor(
    private toastService: ToastService,
    private communicatorService: CommunicatorService,
    private messageBusService: MessageBusService,
    private router: Router,
    private geocoder: MapGeocoder,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    console.log(this.route.snapshot.params);
    const cityName = this.route.snapshot.params['cityName'];
    const clinicName = this.route.snapshot.params['clinicName'];
    if (!cityName) {
      this.toastService.showError("This clinic is not available");
    }
    if (!clinicName) {
      this.toastService.showError("This clinic is not available");
    }
    this.uniqueUrl = `${this.uniqueUrl}${cityName}/${clinicName}`;
    this.getClinicDetailsClinicOverViewApi();
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef?.nativeElement, { types: ['address', 'establishment', 'regions'] },
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place?.geometry?.location) {
          this.initialLat = place.geometry.location.lat();
          this.initialLng = place.geometry.location.lng();
        }
      });
    });
  }

  // dragEnd(event: any) {
  //   this.initialLat = event?.latLng?.lat();
  //   this.initialLng = event?.latLng?.lng();
  // }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.initialLat = position.coords.latitude;
          this.initialLng = position.coords.longitude;
          this.mapCenter = { lat: this.initialLat, lng: this.initialLng };
          this.markers.push({
            position: this.mapCenter,
            label: {
              color: 'red',
              text: 'Your clinic',
            },
            title: 'Your clinic',
            options: { animation: google.maps.Animation.BOUNCE, draggable: false },
          });
          this.locationLoaded = true;
        }
      },
        (error: any) => console.log(error)
      );
    } else {
      alert("Geolocation is not supported by this browser");
      this.toastService.showError("Geolocation is not supported by this browser");
    }
  }

  handlePermission() {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          this.locationLoaded = true;
        } else if (result.state === 'prompt') {
          this.toastService.showWarning("Please allow location access to get your current location");
          this.locationLoaded = false;
        } else if (result.state === 'denied') {
          this.toastService.showError("Geolocation permission denied by this browser");
          this.locationLoaded = false;
        } else {
          this.toastService.showError("Geolocation permission denied by this browser");
          this.locationLoaded = false;
        }
      });
    } else {
      alert("Geolocation is not supported by this browser");
      this.toastService.showError("Geolocation is not supported by this browser");
    }
  }

  getClinicDetailsClinicOverViewApi() {
    this.messageBusService.triggerPageLoader(true);
    const GetClinicDetailsClinicOverViewJson = Object.assign({}, getClinicDetailsClinicOverViewJson);
    GetClinicDetailsClinicOverViewJson.unique_url = this.uniqueUrl;
    this.communicatorService.ApiRunner(GetClinicDetailsClinicOverViewJson).subscribe({
      next: (apiReturn: ApiReturn) => {
        if (this.communicatorService.responseParser(apiReturn)) {
          if (apiReturn.data?.length <= 0) {
            this.toastService.showError(apiReturn.message);
            this.showNotFound = true;
            return;
          }
          this.loader = false;
          this.getClinicOverView = apiReturn.data?.[0];
          this.clinicName = this.getClinicOverView?.name;
          this.cliniqallyCenterId = this.getClinicOverView?.cliniqally_center_id;
          this.aboutClinic = this.getClinicOverView?.about_clinic;
          this.countyCode = this.getClinicOverView?.country_code;
          this.clinicContact = this.getClinicOverView?.phone_number;
          this.emailId = this.getClinicOverView?.email;
          this.clinicLocation = this.getClinicOverView?.location;
          this.clinicCountry = this.getClinicOverView?.country;
          this.clinicState = this.getClinicOverView?.state;
          this.clinicCity = this.getClinicOverView?.city;
          this.clinicAddress = this.getClinicOverView?.address;
          this.clinicPincode = this.getClinicOverView?.pincode;
          this.clinicSpecialities = this.getClinicOverView?.specialities;
          this.days = this.getClinicOverView?.clinic_timings?.map((day: any) => {
            return {
              ...day,
              timing: day.timing.map((timing: any) => {
                return { ...timing, start_time: convertTimeForPublicPage(timing.start_time), end_time: convertTimeForPublicPage(timing.end_time) };
              })
            };
          });
          this.DoctorDataList = this.getClinicOverView?.doctor_details;
          this.initialLng = Number(this.getClinicOverView?.long ?? 77.1025);
          this.initialLat = Number(this.getClinicOverView?.lat ?? 28.7041);
          this.clinicAverageRating = Number(this.getClinicOverView?.average_rating);
          if (this.initialLat && this.initialLng) {
            this.mapCenter = { lat: this.initialLat, lng: this.initialLng };
            this.markers.push({
              position: this.mapCenter,
              label: {
                color: 'red',
                text: this.clinicName,
              },
              title: this.clinicName,
              options: { animation: google.maps.Animation.BOUNCE, draggable: false },
            });
            this.locationLoaded = true;
          }
          this.googleMapsUrl = this.googleMapsUrl + `${this.initialLat},${this.initialLng}`;
          this.handlePermission();
        } else {
          this.toastService.showError(apiReturn.message);
        }
      },
      complete: () => {
        this.messageBusService.triggerPageLoader(false);
      }
    });
  }
}
