import { FormGroup } from '@angular/forms';

import packageJson from '../../../package.json';

export const helpTitle = `Cliniqally`;
export const helpMessage =
  'Something went wrong! Rest assured, we are working on it.';
export const notFoundMessage = 'Requested Api not available.!';
export const onBoardingKey = "onBoardingKey";
export function getApplicationVersion() {
  return packageJson.version;
}

export function getApplicationReleaseDate() {
  return packageJson.description;
}

export function checkIfProfileImageExist(imageUrl: string | undefined | null) {
  return imageUrl && imageUrl != 'null' ? urlEncode(imageUrl) : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6swZO8d-ZWsYcFSMuRaipYJhGTDvJVJ-dIg&usqp=CAU';
}
export function checkIfClinicImageExist(imageUrl: string | undefined) {
  return imageUrl && imageUrl != 'null' ? urlEncode(imageUrl) : 'https://app.cliniqally.com/assets/images/ambulance-icon.png';
}
export enum userTypeEnum {
  admin = "admin",
  doctor = "doctor",
  staff = "staff",
  patient = "patient"
}
export function hasAccess(
  token: any,
  moduleName: moduleName,
  accessLevel: accessLevel,
) {
  try {
    const ModuleName = token?.permissions[moduleName];
    if (ModuleName) {
      const AccessLevel = ModuleName[accessLevel];
      return !!AccessLevel;
    }
    return false;
  } catch (exception) {
    return false;
  }
}
export enum accessLevel {
  create = 'create',
  update = 'update',
  delete = 'delete',
  view = 'view',
}
export enum moduleName {
  Employee = 'Employee',
  Entity = 'Entity',
  'Rig/Warehouse' = 'Rig/Warehouse',
  Buy = 'Buy',
  Sell = 'Sell',
  Company = 'Company',
}
export class ApiReturn {
  status?: any;
  reauth?: boolean;
  licenseExpired?: boolean;
  message = "";
  DetailedMessage?: string;
  StatusCode?: StatusCode;
  data?: any;
  token?: string;
  UserToken?: string;
  url?: string;
}
export enum colorHelper {
  basic = 'basic',
  primary = 'primary',
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  control = 'control',
}
export enum StatusCode {
  OK = 'OK',
  NotFound = 'NotFound',
  InternalServerError = 'InternalServerError',
  InvalidLoginError = 'InvalidLoginError',
}
export enum RestMethod {
  get,
  post,
  put,
  update,
  delete,
  patch,
}
export enum ContentType {
  json = 'application/json',
  formData = 'multipart/form-data;',
}
export enum daysEnum {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}
export enum sessionsEnum {
  EarlyMorning = "Early Morning",
  Morning = "Morning",
  Afternoon = "Afternoon",
  Evening = "Evening",
}
export enum sessionTypeEnum {
  Video = "Video",
  Audio = "Audio",
  Chat = "Chat",
  In_clinic = "In_clinic",
}

export const getIconForSessionType = (sessionType: sessionTypeEnum) => {
  switch (sessionType) {
    case sessionTypeEnum.Video:
      return "pi pi-video";
    case sessionTypeEnum.Audio:
      return "pi pi-phone";
    case sessionTypeEnum.Chat:
      return "pi pi-comments";
    case sessionTypeEnum.In_clinic:
      return "pi pi-home";
  }
};

export interface timing {
  "end_time": string,
  "session_type": sessionTypeEnum,
  "start_time": string,
  "temporarily_unavailable": boolean,
  "time_id": string,
  "un_available": boolean,
  "formattedStartTime"?: string,
  "formattedEndTime"?: string
}

export interface TimingsType {
  "day": string,
  "timing": timing[]
}

export interface countryType {
  country_id: string,
  name: string
}

export interface stateType {
  state_id: string,
  name: string
}

export interface languageType {
  lang_name: string,
  lang_id: string
}

export interface cityType {
  city_id: string,
  name: string
}

export interface OccupationType {
  occupation_id: string,
  occupation: string
}

export interface registrationCouncilType {
  registration_council_id: string,
  registration_council_name: string
}

export interface degreeType {
  degree: string
  degree_id: string
}

export interface bloodGroupsType {
  blood_group: string
  group_id: string
}

export interface timeZoneType {
  time_zone: string
  time_zone_id: string
  time_zone_name: string
}

export interface doctorRolesType {
  designation: string
  role_name: string
}

export interface staffRolesType {
  designation: string
  role_name: string
}

export interface CombinedItemAllVisits {
  isPastVisit: boolean;
  date: string;
  time: string;
  appointment_date: string;
  appointment_time: string;
  consumer_id: string;
  appointment_id: string;
  consultation_id: string;
  appointment_fee: string;
  diagnosis: string;
  status: string;
  token: string;
  consultation_status: string;
  appointment_mode: string;
}

export const sessionsObjectType: { "id": string, "start_time": string; "end_time": string; "session": sessionsEnum }[] = [];

export const daysObjectType: { "day": string, sessions: typeof sessionsObjectType }[] = [];

export function setItem(name: string, token: string) {
  return localStorage.setItem(name, token);
}

export function getItem(name: string) {
  return localStorage.getItem(name);
}

export function removeItem(name: string) {
  return localStorage.removeItem(name);
}
// class starts here
export class CliniqallyUserAppBaseRestApiJson {
  url = '';
  restMethod: RestMethod = RestMethod.get;
  contentType?: ContentType;
  authentication?: boolean = false; //authentication: true will never workout
  pathParameters?: string;
  queryParameters?: string;
  uploadFormData?: FormData;
}

export class CliniqallyUserAppPostFAQRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  mobileIos: boolean;
  mobileAndroid: boolean;
  web: boolean;
}

export class CliniqallyUserAppGetCalendarCountRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export class CliniqallyUserAppGetCalendarRestApiJson extends CliniqallyUserAppGetCalendarCountRestApiJson {
  status: string;
}

export class CliniqallyUserAppLoginMobileRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  countryCode?: string;
  mobileNumber?: string;
  otp?: string;
  deviceType?: string;
}
export class CliniqallyUserAppSignUpRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  firstName?: string;
  lastName?: string;
  countryCode?: string;
  mobileNumber?: string;
}
export class CliniqallyUserAppFinalSignUpRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  countryCode?: string;
  mobileNumber?: string;
  otp?: string;
  fbToken?: string;
}
export class CliniqallyUserAppFinalLoginRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  countryCode: string;
  mobileNumber: string;
  otp?: string;
  deviceType?: string;
  fbToken?: string;
}
export class CliniqallyUserAppCreateDoctorRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  specialization?: string;
  degree?: string;
  reg_number?: string;
  reg_name?: string;
}

// to be implemented later
// export const postLoginJson: CliniqallyUserAppBaseRestApiJson = {
//   url: 'v1/admin/login',
//   restMethod: RestMethod.post,
//   authentication: false,
//   email: '',
//   password: '',
// };

export class CliniqallyUserAppLoginEmailRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  email?: string;
  password?: string;
}

export class CliniqallyUserAppFirstSignUpRestApiJson extends CliniqallyUserAppLoginMobileRestApiJson {
  name?: string;
  firstName?: string;
  lastName?: string;
}
class CliniqallyUserAppCreateProfileRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  userType: string;
  pincode: string;
  about: string;
  email: string;
  referred?: string;
  language?: [];
  specialization?: SpecializationType[];
  degree: [];
  awards?: string;
  membership?: string;
  dob: string;
  gender: string;
  profilePicture: string;
  registrationYear?: string;
  registrationNumber: string;
  registrationName: string;
  timezone: string;
  bloodGroup: string;
  address: string;
  country: string;
  state: string;
  city: string;
  providerAgreement: string;
  whatsappNotifications: boolean;
  deviceType: "web";
  marketing_channel_id: string;
  blood_donation: boolean;
}

export class CliniqallyUserAppCreateClinicRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  email?: string;
  name?: string;
  country_code?: string;
  phone_number?: string;
  about_clinic?: string;
  location?: string;
  location_json?: object;
  type?: string;
  specialities?: string;
  representative_code?: string;
  country?: string;
  state?: string;
  city?: string;
  address?: string;
  pincode?: string;
  timezone?: string;
  lat?: string;
  long?: string;
  clinicTimings?: clinicTimingsType[];
  assistance?: boolean;
  currency: string;
  clinic_gst_no: string;
  clinic_pan_no: string;
}
export class CliniqallyUserApSwitchClinicRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  centerId?: string;
}
export class CliniqallyUserAppAddAppointmentRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  consumer_id: string;
  appointment_mode: string;
  appointment_date: string;
  provider_id: string;
  appointment_time: string;
  appointment_duration?: string;
  notify_via_whatsapp: boolean;
  notify_via_sms: boolean;
  planned_procedures?: [];
  notes: string;
  status: string;
}

export class CliniqallyAddStaffRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  providerId: string;
  firstName: string;
  lastName: string;
  pincode: string;
  about: string;
  userType: string;
  email: string;
  referred: string;
  bloodGroup: string;
  language: [];
  awards: string;
  membership: string;
  dob: string;
  gender: string;
  timezone: string;
  address: string;
  country: string;
  state: string;
  city: string;
  providerAgreement: boolean;
  whatsappNotifications: boolean;
  designation: any;
  blood_donation: boolean;
}

class CliniqallyPostDoctorDataRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  userType: string;
  first_name: string;
  last_name: string;
  pincode: string;
  about: string;
  email: string;
  referred?: string;
  language?: [];
  specialization?: SpecializationType[];
  degree: [];
  awards?: string;
  membership?: string;
  dob: string;
  gender: string;
  profilePicture: string;
  registrationYear?: string;
  registrationNumber: string;
  registrationName: string;
  timezone: string;
  bloodGroup: string;
  address: string;
  country: string;
  state: string;
  city: string;
  providerAgreement: boolean;
  whatsappNotifications: boolean;
}

export class CliniqallyUserAppAddStaffRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  providerId: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  pincode: string;
  referred: string;
  profilePicture: string;
  timezone: string;
  whatsappNotifications: boolean;
  providerAgreement: boolean;
  registrationNumber: string;
  registrationName: string;
  registrationYear: string;
  about: string;
  designation: string;
  awards: string;
  membership: string;
  specialization?: SpecializationType[];
  degree: [];
  language: [];
  userType: string;
  bloodGroup: string;
  blood_donation: boolean;
}

// reminder Appointment

export class CliniqallyUserAppReminderRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  reminder_title: string;
  doctor: string;
  duration_type: string;
  scheduled_date: string;
  scheduled_time?: string;
}

export class CliniqallyUserAppAppointmentSettingsRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  session_type: string;
  providerTimings: timeSessions[];
}

export class CliniqallyUserAppAppointmentSettingsV2RestApiJson extends CliniqallyUserAppBaseRestApiJson {
  providerTimings: appointmentSettings[];
}

// add patient with appointment
export class CliniqallyUserAppAddPatientWithAppointmentRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  provider_id: string;
  firstName: string;
  lastName: string;
  email: string;
  country_code: string;
  mobile: string;
  gender: string;
  dob: string;
  address: string;
  country: string;
  state: string;
  city: string;
  emergency_contact_country_code: string;
  emergency_contact: string;
  blood_group: string;
  height: string;
  weight: string;
  timezone: string;
  occupation: string;
  appointment_mode: string;
  appointment_date: string;
  appointment_time: string;
  notify_via_whatsapp: boolean;
  notify_via_sms: boolean;
  planned_procedures?: string[];
  notes: string;
  annual_income: string;
  blood_donation: boolean;
}

export class CliniqallyUserAppGetAppointmentDetailsRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  provider_id: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  sessionType: string;
}
export class CliniqallyUserAppListDoctorsRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  provider_id: string;
  year_of_experience: string;
  degree: string;
}
// class ends here

// interface starts here
export const createDoctorJson: CliniqallyUserAppCreateDoctorRestApiJson = {
  url: "v1/service-provider/service-provider-profile",
  restMethod: RestMethod.post,
  authentication: true,
  specialization: '',
  degree: '',
  reg_number: '',
  reg_name: '',
};

export const postSignUpMobileJson: CliniqallyUserAppSignUpRestApiJson = {
  url: 'v1/service-provider/sign-up-step-one',
  restMethod: RestMethod.post,
  authentication: false,
  firstName: '',
  lastName: '',
  countryCode: '',
  mobileNumber: '',
};

export const postSignUpMobileOtpJson: CliniqallyUserAppFinalSignUpRestApiJson = {
  url: 'v1/service-provider/sign-up-step-two',
  restMethod: RestMethod.post,
  authentication: false,
  countryCode: '',
  mobileNumber: '',
  otp: '',
};

export const getListJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/create-profile-get-details",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getCreateClinicDataJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/clinic/create-clinic-get-details",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getStateJson: CliniqallyUserAppBaseRestApiJson = {

  url: "v1/service-provider/get-states",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getCitiesJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-cities",
  restMethod: RestMethod.get,
  authentication: true,
};

export const postLoginMobileJson: CliniqallyUserAppLoginMobileRestApiJson = {
  url: 'v1/service-provider/login-mobile',
  restMethod: RestMethod.post,
  authentication: false,
  countryCode: '',
  mobileNumber: '',
  deviceType: "web"
};

export const postLoginMobileOtpJson: CliniqallyUserAppFinalLoginRestApiJson = {
  url: 'v1/service-provider/login-mobile',
  restMethod: RestMethod.post,
  authentication: false,
  countryCode: '',
  mobileNumber: '',
  otp: '',
  deviceType: "web",
  fbToken: ''
};

export const getSpecializationJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/get-sub-specialization',
  restMethod: RestMethod.get,
  authentication: true,
};

export const postCreateProfile: CliniqallyUserAppCreateProfileRestApiJson = {
  url: 'v1/service-provider/create-profile',
  restMethod: RestMethod.post,
  authentication: true,
  userType: '',
  pincode: '',
  about: '',
  email: '',
  referred: '',
  language: [],
  specialization: [],
  degree: [],
  awards: '',
  membership: '',
  dob: '',
  gender: '',
  profilePicture: '',
  registrationYear: '',
  registrationNumber: '',
  registrationName: '',
  timezone: '',
  bloodGroup: '',
  address: '',
  country: '',
  state: '',
  city: '',
  providerAgreement: '',
  whatsappNotifications: false,
  marketing_channel_id: '',
  blood_donation: false,
  deviceType: "web"
};

export const uploadServiceProviderProfilePicture: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/update-service-provider-profile-picture",
  restMethod: RestMethod.post,
  authentication: true,
  contentType: ContentType.formData,
  uploadFormData: new FormData()
};

export const createClinicJson: CliniqallyUserAppCreateClinicRestApiJson = {
  url: "v1/service-provider/clinic/create-clinic",
  restMethod: RestMethod.post,
  authentication: true,
  email: '',
  name: '',
  country_code: '',
  phone_number: '',
  about_clinic: '',
  location: '',
  location_json: {},
  type: '',
  specialities: '',
  representative_code: '',
  assistance: false,
  country: '',
  state: '',
  city: '',
  address: '',
  pincode: '',
  timezone: '',
  currency: '',
  lat: '',
  long: '',
  clinicTimings: [],
  clinic_gst_no: '',
  clinic_pan_no: ''
};
export const getClinicJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-clinics",
  restMethod: RestMethod.get,
  authentication: true,
};
export const switchClinicJson: CliniqallyUserApSwitchClinicRestApiJson = {
  url: 'v1/service-provider/switch-clinic',
  restMethod: RestMethod.post,
  authentication: true,
  centerId: '',
};
export const getAddBlockCalendarDetailsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-block-appointment",
  restMethod: RestMethod.get,
  authentication: true,
};
// add-reminder
export const getDoctorListJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-block-appointment",
  restMethod: RestMethod.get,
  authentication: true,
};

// add appointment post
export const addAppointmentJson: CliniqallyUserAppAddAppointmentRestApiJson = {
  url: "v1/service-provider/add-appointment",
  restMethod: RestMethod.post,
  authentication: true,
  consumer_id: '',
  appointment_mode: '',
  appointment_date: '',
  provider_id: '',
  appointment_time: '',
  notify_via_whatsapp: false,
  notify_via_sms: false,
  planned_procedures: [],
  notes: '',
  status: ''

};
// list by clinic
export const getListByClinicJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/clinic/get-service-provider-list-by-clinic/doctor",
  restMethod: RestMethod.get,
  authentication: true,
};
// get clinic info
export const getClinicInfoJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/clinic/get-clinic-info",
  restMethod: RestMethod.get,
  authentication: true,
};
//add staff
export const addStaffProfileJson: CliniqallyAddStaffRestApiJson = {
  url: "v1/service-provider/add-clinic-staff",
  restMethod: RestMethod.post,
  authentication: true,
  providerId: '',
  firstName: '',
  lastName: '',
  pincode: '',
  about: '',
  userType: '',
  email: '',
  referred: '',
  bloodGroup: '',
  language: [],
  awards: '',
  membership: '',
  dob: '',
  gender: '',
  timezone: '',
  address: '',
  country: '',
  state: '',
  city: '',
  providerAgreement: false,
  whatsappNotifications: false,
  designation: '',
  blood_donation: false
};

export const getAddPatientDetailsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/create-patient-get-details",
  restMethod: RestMethod.get,
  authentication: true,
};
export const addStaffsSendOtpJson: CliniqallyUserAppLoginMobileRestApiJson = {
  url: 'v1/service-provider/add-staff-generate-otp',
  restMethod: RestMethod.post,
  authentication: false,
  countryCode: '',
  mobileNumber: '',
};

export const postAddDoctor: CliniqallyPostDoctorDataRestApiJson = {
  url: 'v1/service-provider/add-doctor',
  restMethod: RestMethod.post,
  authentication: true,
  first_name: '',
  last_name: '',
  userType: '',
  pincode: '',
  about: '',
  email: '',
  referred: '',
  language: [],
  specialization: [],
  degree: [],
  awards: '',
  membership: '',
  dob: '',
  gender: '',
  profilePicture: '',
  registrationYear: '',
  registrationNumber: '',
  registrationName: '',
  timezone: '',
  bloodGroup: '',
  address: '',
  country: '',
  state: '',
  city: '',
  providerAgreement: false,
  whatsappNotifications: false
};

export const addStaffGenerateOtpJson: CliniqallyUserAppFinalLoginRestApiJson = {
  url: 'v1/service-provider/add-staff-generate-otp',
  restMethod: RestMethod.post,
  authentication: true,
  countryCode: '',
  mobileNumber: '',
  otp: ''
};


export const addStaffVerifyOtpJson: CliniqallyUserAppFinalLoginRestApiJson = {
  url: 'v1/service-provider/add-staff-verify-otp',
  restMethod: RestMethod.post,
  authentication: true,
  countryCode: '',
  mobileNumber: '',
  otp: ''
};
export const postStaffDetailsJson: CliniqallyUserAppAddStaffRestApiJson = {
  url: 'v1/service-provider/add-clinic-staff',
  restMethod: RestMethod.post,
  authentication: true,
  providerId: '',
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  gender: '',
  address: '',
  country: '',
  state: '',
  city: '',
  pincode: '',
  referred: '',
  profilePicture: '',
  timezone: '',
  whatsappNotifications: true,
  providerAgreement: true,
  registrationNumber: '',
  registrationName: '',
  registrationYear: '',
  about: '',
  designation: '',
  awards: "",
  membership: "",
  specialization: [],
  degree: [],
  language: [],
  userType: '',
  bloodGroup: '',
  blood_donation: true
};
export const postBlockAppointmentJson: CliniqallyUserAppBlockCalendarApiJson = {
  url: "v1/service-provider/consumer/create-patient",
  restMethod: RestMethod.post,
  appointment_mode: '',
  provider_id: '',
  duration_type: '',
  appointment_date: '',
  appointment_start_time: '',
  appointment_end_time: '',
  notes: ''
};

// add patient with appointment
export const addPatientWithAppointmentJson: CliniqallyUserAppAddPatientWithAppointmentRestApiJson = {
  url: 'v1/service-provider/patient/create-patient',
  restMethod: RestMethod.post,
  authentication: true,
  provider_id: '',
  firstName: '',
  lastName: '',
  email: '',
  country_code: '',
  mobile: '',
  gender: '',
  dob: '',
  address: '',
  country: '',
  state: '',
  city: '',
  emergency_contact: '',
  emergency_contact_country_code: '',
  blood_group: '',
  height: '',
  weight: '',
  timezone: '',
  occupation: '',
  appointment_mode: '',
  appointment_date: '',
  appointment_time: '',
  notify_via_whatsapp: true,
  notify_via_sms: true,
  planned_procedures: [],
  notes: '',
  annual_income: '',
  blood_donation: true,
};

// get appointment slot details
export const getAppointmentSlotDetailsJson: CliniqallyUserAppGetAppointmentDetailsRestApiJson = {
  url: 'v1/service-provider/get-appointment-slots',
  restMethod: RestMethod.post,
  authentication: true,
  provider_id: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  sessionType: ''
};
// add staff
export const getAddClinicStaffDetailsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/add-clinic-staff-get-details",
  restMethod: RestMethod.get,
  authentication: true,
};
// edit patient list
export const GetPatientJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consumer/list-patient",
  restMethod: RestMethod.get,
  authentication: true,
};

//patient list
export const getPatientListJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consumer/list-patient",
  restMethod: RestMethod.get,
  authentication: true,
};

//List chiefComplaint
export const getEmrListJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consultation/emr-list-apis",
  restMethod: RestMethod.get,
  authentication: true,
};

//List get consultation details by id
export const getEmrJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consultation/get-consultation",
  restMethod: RestMethod.get,
  authentication: true,
};

//List get pastVisits details by consumerid
export const getPastVisitsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consumer/get-past-visit-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: '',
};

export const postAppointmentReminderDetailsJson: CliniqallyUserAppReminderRestApiJson = {
  url: 'v1/service-provider/add-clinic-staff',
  restMethod: RestMethod.post,
  authentication: true,
  reminder_title: '',
  doctor: '',
  duration_type: '',
  scheduled_date: '',
  scheduled_time: ''
};

// appointment settings
export const appointmentSettingsJson: CliniqallyUserAppAppointmentSettingsRestApiJson = {
  url: "v1/service-provider/appointment-settings",
  restMethod: RestMethod.post,
  authentication: true,
  session_type: '',
  providerTimings: [],
};

export const appointmentSettingsV2Json: CliniqallyUserAppAppointmentSettingsV2RestApiJson = {
  url: "v1/service-provider/appointment-settings-v2",
  restMethod: RestMethod.post,
  authentication: true,
  providerTimings: [],
};

export interface session {
  id?: number,
  startTime: string,
  endTime: string,
}

export interface sessionInterface {
  id?: number,
  time_id?: string,
  end_time: string,
  start_time: string
}

export interface timeSessions {
  id?: number,
  "day": string,
  "duration": number,
  "fee": number,
  "sessions": session[]
}

export interface appointmentTimings {
  session_type: appointmentModesEnum,
  timeSessions: timeSessions[],
  total_rows: string
}

export interface appointmentSettingsSession {
  id?: number,
  start_time: string;
  end_time: string;
}

export interface appointmentSettings {
  id: number,
  day: string[];
  session_type: string[];
  fee: string;
  duration: number;
  sessions: appointmentSettingsSession[];
}

// search patient
export const getResultSearchPatientJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/patient/get-clinic-patient",
  restMethod: RestMethod.get,
  authentication: true,
};
export const getPatientJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consumer/get-patient",
  restMethod: RestMethod.get,
  authentication: true,
};
// list dr
export const listDoctorsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/clinic/get-service-provider-list-by-clinic/doctor",
  restMethod: RestMethod.get,
  authentication: true,
};
export const listStaffsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/clinic/get-service-provider-list-by-clinic/staff",
  restMethod: RestMethod.get,
  authentication: true,
};
// interface ends here
export enum popupModalsList {
  switchclinic = 'switch-clinic',
  googleRewards = 'google-listing',
  customizedAppointment = 'customized-appointment',
  youtubeChannel = 'creating-a-youtube-channel',
  yourOwnBlog = 'your-own-blog',
  amazonGiftVoucher = 'amazon-gift-voucher',
  onlineReputationBuilder = 'online-reputation-builder',
  videoEditing = 'video-editing-and-posting',
  delayAppointment = 'delay-appointment',
  addAppointment = 'add-appointment',
  endConsultation = 'end-consultation',
  addNewClinic = 'add-new-clinic',
  withdrawHistory = 'withdraw-history',
  referralDetails = 'referral-details',
  addBankDetails = 'add-bank-details',
  cancelWithdrawRequest = 'cancel-withdraw-request',
  addBankDetailsForm = 'add-bank-details-form',
  withdrawAmount = 'withdraw-amount',
  withdrawAmountSuccess = 'withdraw-amount-success',
  bankDetails = 'bank-details',
  addPatientWithAppointment = 'add-patient-with-appointment'
}

export interface addStaffDataType {
  id: string,
  specialization_id?: string;
  sub_specializations?: [
    {
      sub_specialization_id: string;
      experience: string;
    }
  ];
}
export interface appointmentListDataType {
  appointment_time: string;
  consumer_id: string;
  count: string;
  country_code: string;
  first_name: string;
  gender: string;
  last_name: string;
  mobile: string;
  provider_id: string;
  appointment_id: string;
  consultation_id: string;
  age: string;
  token: string;
  appointment_date: string;
  appointment_mode: appointmentModesEnum;
  appointmentTimesString: string;
  appointmentDateTime: string;
  status: string;
  rescheduled: boolean;
  delay: number;
}

export enum appointmentModesEnum {
  Video = "Video",
  Audio = "Audio",
  In_clinic = "In_clinic",
  Chat = "Chat",
  All = 'All'
}

//add patient class
export class CliniqallyUserAppAddPatientApiJson extends CliniqallyUserAppBaseRestApiJson {
  provider_id: string;
  firstName: string;
  lastName: string;
  email: string;
  country_code: string;
  mobile: string;
  gender: string;
  dob: string;
  address: string;
  country: string;
  state: string;
  city: string;
  emergency_contact_country_code: string;
  emergency_contact: string;
  blood_group: string;
  height: string;
  weight: string;
  timezone: string;
  occupation: string;
  annual_income: string;
  pet_type_id?: string;
  relation_type_id?: string;
  breed?: string;
  parent_consumer_id?: string;
  consumer_id?: string;
  blood_donation?: boolean;
}

// emr post api upsert-consultation
export class CliniqallyUpsertConsultationApiJson extends CliniqallyUserAppBaseRestApiJson {
  appointment_id: string;
  chief_complaint: string;
  examination: boolean;
  bp: string;
  weight: string;
  temperature: string;
  height: string;
  respiratory_rate: string;
  pulse: string;
  examination_notes: string;
  allergies: string;
  habits: string;
  diagnosis: string;
  procedures: string;
  surgeries: string;
  investigation: string;
  advice: string;

}
// post block calendar
export class CliniqallyUserAppBlockCalendarApiJson extends CliniqallyUserAppBaseRestApiJson {
  appointment_mode: string;
  provider_id: string;
  duration_type: string;
  appointment_date: string;
  appointment_start_time: string;
  appointment_end_time: string;
  notes: string;
}

export interface clinicTimingsType {
  id?: number;
  day: string;
  timing: session[];
}

export interface clinicDayAndTiming {
  id?: number;
  day: string;
  timing: sessionInterface[];
}

// add staff from dashboard
export interface addStaffInterface {
  userType: string;
  providerId: string;
  userExist: boolean;
  countryCode: string;
  mobileNumber: string;
  profileIncomplete: boolean;
}


export interface consumerIdInterface {
  consumer_id: string;
}

// add patient
export const addPatientJson: CliniqallyUserAppAddPatientApiJson = {
  url: 'v1/service-provider/consumer/create-patient',
  restMethod: RestMethod.post,
  authentication: true,
  provider_id: '',
  firstName: '',
  lastName: '',
  email: '',
  country_code: '',
  mobile: '',
  gender: '',
  dob: '',
  address: '',
  country: '',
  state: '',
  city: '',
  emergency_contact_country_code: '',
  emergency_contact: '',
  blood_group: '',
  height: '',
  weight: '',
  timezone: '',
  occupation: '',
  annual_income: '',
  pet_type_id: '',
  relation_type_id: '',
  breed: '',
  parent_consumer_id: '',
  consumer_id: '',
  blood_donation: false,

};
// appointment list count get api
export const getAppointmentCountJson: CliniqallyUserAppGetCalendarCountRestApiJson = {
  url: "v1/service-provider/get-appointment-count",
  restMethod: RestMethod.post,
  authentication: true,
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: ''
};

// appointment list api get
export const getAppointmentListJson: CliniqallyUserAppGetCalendarRestApiJson = {
  url: "v1/service-provider/get-calendar",
  restMethod: RestMethod.post,
  authentication: true,
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  status: '',
};

// export function
export interface AppointmentSlotsInterface {
  day: number
  dayName: string
  isBooked: boolean
  sessionType: string
  slot: string,
  slotConverted: string
}

export function decodeText(inputString: string) {
  const decodedText = decodeURIComponent(inputString);
  const txt = document.createElement("textarea");
  txt.innerHTML = decodedText;
  return txt.value;
}

export function urlEncode(inputString: string) {
  const en = decodeURIComponent(inputString);
  return en;
}

export interface BlockCalendarGetDataType {
  leaveDetails: LeaveDetailsDataType[];
  providerDetails: DoctorListDataType[];
}
export interface LeaveDetailsDataType {
  leavedetail_id: string;
  leavedetail_name: string;
  leavedetail_reason: string;
}
export interface DoctorListDataType {
  default_user_type: string;
}

export interface dateDataType {
  startDate: string,
  endDate: string,
  start_time: string,
  end_time: string,
  status: appointmentStatusEnum
}

export enum appointmentStatusEnum {
  All = "All",
  scheduled = "scheduled",
  cancelled = "cancelled",
  created = "created",
  payment_success = "payment_success",
  payment_failed = "payment_failed",
  payment_pending = "payment_pending",
  checked_in = "checked_in",
  inprogress = "inprogress",
  completed = "completed",
  expired = "expired",
}

export enum appointmentStatusNamesEnum {
  All = "All",
  scheduled = "Scheduled",
  cancelled = "Cancelled",
  active = "Active",
  completed = "Completed",
  waiting = "Waiting",
  created = "Created",
  payment_success = "Payment Success",
  payment_failed = "PaymentFailed",
  checked_in = "Checked In",
  inprogress = "In Progress",
  expired = "Expired",
}

// delete patient
export const deletePatientJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/consumer/delete-patient',
  restMethod: RestMethod.post,
  authentication: true,
};

export const getAppointmentTimingsJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/get-appointment-timings',
  restMethod: RestMethod.get,
  authentication: true,
};

export const getAppointmentSettingsJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/get-appointment-settings-v2',
  restMethod: RestMethod.get,
  authentication: true,
};

export interface AppointmentStatusCountApi { appointment_status: appointmentStatusEnum, occurrences: string }

// create panel get doctor list
export const getProvidersOfAdmin: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-providers-of-admin",
  restMethod: RestMethod.get,
  authentication: true,
};

export const listProviders: CliniqallyUserAppListProviderStaffRestApiJson = {
  url: "v1/service-provider/list-provider-staff",
  restMethod: RestMethod.post,
  authentication: true,
  providerId: '',
};
export class CliniqallyUserAppAddProvidersStaffsRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  provider: string;
  staff: [];
}

export class CliniqallyUserAppDeleteAssignStaffRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  providerId: string;
  assignedServiceProvider: string;
}
export const deleteProvidersJson: CliniqallyUserAppDeleteAssignStaffRestApiJson = {
  url: "v1/service-provider/delete-provider-staff",
  restMethod: RestMethod.post,
  authentication: true,
  providerId: '',
  assignedServiceProvider: ''
};
export const getProvidersJson: CliniqallyUserAppGetProvidersToAssignRestApiJson = {
  url: "v1/service-provider/get-providers-to-assign",
  restMethod: RestMethod.post,
  authentication: true,
  provider: '',
};
export const addProvidersJson: CliniqallyUserAppAddProvidersStaffsRestApiJson = {
  url: "v1/service-provider/add-provider-staffs",
  restMethod: RestMethod.post,
  authentication: true,
  provider: '',
  staff: []
};
// class for create panel
export class CliniqallyUserAppCreatePanelApiJson extends CliniqallyUserAppBaseRestApiJson {
  status: string;
  panelName: any;
  panelProviders: any[];
}
// interface for create panel
export const createProcedureJson: CliniqallyUserAppCreatePanelApiJson = {
  url: "v1/service-provider/panels/create-panel",
  restMethod: RestMethod.post,
  authentication: true,
  panelName: '',
  panelProviders: [],
  status: ''
};

// class for create procedure
export class CliniqallyUserAppCreateMedicalProcedureApiJson extends CliniqallyUserAppBaseRestApiJson {
  procedureName: string;
  description: string;
  panel: string;
  procedureCode: any;
  procedurePrice: any;
  taxApplicable: string;
  taxType: any;
  status: any;
}

// interface for create procedure
export const createMedicalProcedureJson: CliniqallyUserAppCreateMedicalProcedureApiJson = {
  url: "v1/service-provider/procedures/add-clinic-medical-procedure",
  restMethod: RestMethod.post,
  authentication: true,
  procedureName: '',
  description: '',
  panel: '',
  procedureCode: '',
  procedurePrice: '',
  taxApplicable: '',
  taxType: '',
  status: '',
};

// get panel list for create procedure
export const createProcedureGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/panels/create-procedure-get-details",
  restMethod: RestMethod.get,
  authentication: true,
};

// get clinic info
export const getClinicDetailsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/clinic/get-clinic",
  restMethod: RestMethod.get,
  authentication: true,
};

// update clinic
export class CliniqallyUserAppUpdateClinicRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  email?: string;
  name?: string;
  country_code?: string;
  phone_number?: string;
  about_clinic?: string;
  location?: string;
  specialities?: string;
  city?: string;
  address?: string;
  pincode?: string;
  lat?: string;
  long?: string;
  clinicTimings?: clinicTimingsType[];
  clinic_gst_no: string;
  clinic_pan_no: string;
}
export const updateClinicJson: CliniqallyUserAppUpdateClinicRestApiJson = {
  url: "v1/service-provider/clinic/update-clinic",
  restMethod: RestMethod.patch,
  authentication: true,
  email: '',
  name: '',
  country_code: '',
  phone_number: '',
  about_clinic: '',
  location: '',
  specialities: '',
  city: '',
  address: '',
  pincode: '',
  lat: '',
  long: '',
  clinicTimings: [],
  clinic_gst_no: '',
  clinic_pan_no: '',
};
// list procedure
export const listProcedureGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/procedures/list-clinic-medical-procedure",
  restMethod: RestMethod.get,
  authentication: true,

};

// delete procedure
export const deleteProcedureJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/procedures/delete-clinic-medical-procedure',
  restMethod: RestMethod.post,
  authentication: true,
};

// search procedure
export const resultSearchProcedureJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/procedures/search-procedure",
  restMethod: RestMethod.post,
  authentication: true,
};

export function findInvalidInForm(f: FormGroup) {
  const invalid: string[] = [];
  const controls = f.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  console.table(invalid);
  return invalid;
}
// add appointment -> clinic's patient list
export const getClinicPatientListJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consumer/list-patient-dropdown",
  restMethod: RestMethod.get,
  authentication: true,
};

// add appointment -> medical procedure list
export const getMedicalProcedureJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/procedures/list-clinic-medical-procedure",
  restMethod: RestMethod.get,
  authentication: true,
};

// console for form control
export function transformString(inputString: string | undefined) {
  if (inputString) {
    let returnString = inputString.replaceAll('_', ' ') ?? '';
    returnString = returnString.split("_")
      .filter(x => x.length > 0)
      .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
      .join(" ");
    return returnString;
  }
  return "";
}

// list Medicine
export const listMedicineGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/medication/list-medications",
  restMethod: RestMethod.get,
  authentication: true,
};

// delete Medicine
export const deleteMedicineJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/medication/delete-medication',
  restMethod: RestMethod.post,
  authentication: true,
};

// get list for adding medicine
export const getMedicationJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/medication/get-medication-details",
  restMethod: RestMethod.get,
  authentication: true,
};

// class for create medicine
export class CliniqallyUserAppCreateMedicineApiJson extends CliniqallyUserAppBaseRestApiJson {
  medicine_name: string;
  description: string;
  status: string;
  medicationDurationId: string[];
  medicationFrequencyId: string[];
  chiefComplaints: string;
  instruction: string;
}

// interface for create medicine
export const createMedicineJson: CliniqallyUserAppCreateMedicineApiJson = {
  url: "v1/service-provider/medication/create-medication",
  restMethod: RestMethod.post,
  authentication: true,
  medicine_name: '',
  description: '',
  status: '',
  chiefComplaints: '',
  instruction: '',
  medicationDurationId: [],
  medicationFrequencyId: []
};

// search result Medicine
export const searchResultMedicineGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/medication/list-medications",
  restMethod: RestMethod.get,
  authentication: true,
};

// class for add custom duration in add medicine
export class CliniqallyUserAppAddCustomDurationMedicineApiJson extends CliniqallyUserAppBaseRestApiJson {
  duration: string;
}

// interface for add custom duration in add medicine
export const customDurationJson: CliniqallyUserAppAddCustomDurationMedicineApiJson = {
  url: "v1/service-provider/medication/create-duration",
  restMethod: RestMethod.post,
  authentication: true,
  duration: ''
};

// class for add custom frequency in add medicine
export class CliniqallyUserAppAddCustomFrequencyMedicineApiJson extends CliniqallyUserAppBaseRestApiJson {
  morning: string;
  afternoon: string;
  evening: string;
}

// interface for add custom frequency in add medicine
export const customFrequencyJson: CliniqallyUserAppAddCustomFrequencyMedicineApiJson = {
  url: "v1/service-provider/medication/create-frequency",
  restMethod: RestMethod.post,
  authentication: true,
  morning: '',
  afternoon: '',
  evening: ''
};

// scheduled status in consultation flow
export const checkedInJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/update-status-checked-in",
  restMethod: RestMethod.patch,
  authentication: true,
};

export class CliniqallyUserAppStartConsultationApiJson extends CliniqallyUserAppBaseRestApiJson {
  isDoctorConcerned: any;
}

// start consultation in consultation flow
export const startConsultationJson: CliniqallyUserAppStartConsultationApiJson = {
  url: "v1/service-provider/consultation/start-consultation",
  restMethod: RestMethod.post,
  authentication: true,
  isDoctorConcerned: ''
};

// class for upsert consultation
export class CliniqallyUserAppUpsertConsultationApiJson extends CliniqallyUserAppBaseRestApiJson {
  appointment_id: string;
  chief_complaint: string[];
  followUpDays: string;
  examination: string;
  bp: string;
  weight: string;
  temperature: string;
  height: string;
  respiratory_rate: string;
  pulse: string;
  examination_notes: string;
  allergies: string[];
  habits: string[];
  medication: any[];
  diagnosis: string;
  procedures: string[];
  surgeries: string[];
  investigation: string;
  advice: string;
  status: boolean;
}

// interface for upsert consultation
export const upsertConsultationJson: CliniqallyUserAppUpsertConsultationApiJson = {
  url: "v1/service-provider/consultation/upsert-consultation",
  restMethod: RestMethod.post,
  authentication: true,
  appointment_id: '',
  chief_complaint: [],
  followUpDays: '',
  examination: '',
  bp: '',
  weight: '',
  temperature: '',
  height: '',
  respiratory_rate: '',
  pulse: '',
  examination_notes: '',
  allergies: [],
  habits: [],
  medication: [],
  diagnosis: '',
  procedures: [],
  surgeries: [],
  investigation: '',
  advice: '',
  status: true
};

// class for post consultation chat
export class CliniqallyConsultationChatApiJson extends CliniqallyUserAppBaseRestApiJson {
  message: string;
  applicationName: string;
  applicationVersion: string;
  applicationType: string;
}

// get medical records
export const getMedicalRecordsGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-medical-record-type",
  restMethod: RestMethod.get,
  authentication: true,
};


// get consultation chats
export const getConsultationChatGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consultation/consultation-chats",
  restMethod: RestMethod.get,
  authentication: true,
};

// get consultation video call
export const getConsultationVideoPostJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consultation/consultation-video-transaction",
  restMethod: RestMethod.post,
  authentication: true,
};

// get Attachments
export const getAttachmentsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-attachments",
  restMethod: RestMethod.get,
  authentication: true,
};

export interface MedicalRecordsMergedArrayInterface {
  _id: string;
  record_id: string;
  provider_id: string;
  consumer_id: string;
  center_id: string;
  record_type: string;
  medical_record: string;
  appointment_id: string;
  uploaded_by: string;
  createdAt: string;
  updatedAt: string;
  formattedDate: string;
  formattedTime: string;
}

// post consultation chat
export const consultationChatPostJson: CliniqallyConsultationChatApiJson = {
  url: "v1/service-provider/consultation/consultation-chat",
  restMethod: RestMethod.post,
  authentication: true,
  message: '',
  applicationName: 'service_provider',
  applicationVersion: getApplicationVersion(),
  applicationType: 'web'
};

export interface ChatData {
  text: string,
  profileUrl?: string,
  date: Date,
  reply: boolean,
  retry?: boolean,
  type: string,
  user: {
    name: string,
    avatar: string
  }
}

// get patient vitals
export const patientVitalsGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/vitals/get-vital-signs",
  restMethod: RestMethod.get,
  authentication: true,
};

// class for create vitals
export class CliniqallyUserAppCreateVitalsApiJson extends CliniqallyUserAppBaseRestApiJson {
  consumer_id: string;
  appointment_id: string;
  is_diabetic: boolean;
  random_glucose_value: string;
  bp_low: string;
  bp_high: string;
  weight: string;
  height: string;
  temperature: string;
  pulse: string;
  respiratory_rate: string;
  examination_notes: string;
  spo2: string;
}

// interface for create vitals
export const createVitalsJson: CliniqallyUserAppCreateVitalsApiJson = {
  url: "v1/service-provider/vitals/create-vital-signs",
  restMethod: RestMethod.post,
  authentication: true,
  appointment_id: '',
  consumer_id: '',
  is_diabetic: false,
  random_glucose_value: '',
  bp_low: '',
  bp_high: '',
  weight: '',
  height: '',
  temperature: '',
  pulse: '',
  respiratory_rate: '',
  examination_notes: '',
  spo2: '',
};

// class for update vitals
export class CliniqallyUserAppUpdateVitalsApiJson extends CliniqallyUserAppBaseRestApiJson {
  is_diabetic: string;
  random_glucose_value: string;
  bp_low: string;
  bp_high: string;
  weight: string;
  height: string;
  temperature: string;
  pulse: string;
  respiratory_rate: string;
  examination_notes: string;
  spo2: string;
}

// interface for update vitals
export const updateVitalsJson: CliniqallyUserAppUpdateVitalsApiJson = {
  url: "v1/service-provider/vitals/create-vital-signs",
  restMethod: RestMethod.post,
  authentication: true,
  is_diabetic: '',
  random_glucose_value: '',
  bp_low: '',
  bp_high: '',
  weight: '',
  height: '',
  temperature: '',
  pulse: '',
  respiratory_rate: '',
  examination_notes: '',
  spo2: '',
};

export interface getPatientDataInterface {
  appointmentId: string;
  providerId: string;
  patientName: string;
  countryCode: string;
  mobileNumber: string;
}

export interface GetEmrJsonInterface {
  status: string;
  appointmentDateTime: string;
  appointmentTimesString: string;
  appointment_mode: string;
  appointmentStatus: string;
  consultation_id: string;
  center_id: string;
  appointment_id: string;
  provider_id: string;
  age: string;
  chief_complaint: null;
  token: string;
  diagnosis: null;
  investigation: null;
  advice: null;
  consultation_status: string;
  bp_low: null;
  bp_high: null;
  weight: null;
  temp: null;
  height: null;
  respiratory_rate: null;
  pulse: null;
  examination_notes: null;
  follow_up_days: null;
  consumer_id: string;
  first_name: string;
  last_name: string;
  profile_picture: null;
  email: string;
  mobile: string;
  country_code: string;
  gender: string;
  dob: Date;
  medications: MedicationDetailInterface[];
  allergies: null;
  chief_complaints: null;
  habits: null;
  medical_procedures: null;
  surgeries: null;
  appointment_time: string,
  appointment_duration: string,
  appointment_date: string,
  is_followup: string
}
export interface GetVitalSignsInterface {
  provider_added_data: {
    bp_low: string;
    bp_high: string;
    temp: string;
    height: string;
    weight: string;
    pulse: string;
    respiratory_rate: string;
    examination_notes: string;
    random_glucose_value: string;
    spo2: string;
  };
  patient_added_random_glucose_value?: any;
  patient_added_is_diabetic?: any;
  patient_added_gender: string;
  patient_added_weight: string;
  patient_added_height: string;
  patient_added_blood_group: string;
}

export interface MedicalRecordsInterface {
  _id: string;
  record_id: string;
  provider_id: string;
  consumer_id: string;
  center_id: string;
  record_type: string;
  medical_record: string;
  appointment_id: string;
  uploaded_by: string;
  createdAt: string;
  updatedAt: string;
}
export interface MedicationDetailInterface {
  selectedDurationId: any;
  selectedFrequencyId: any;
  selectedInstruction: any
  frequency: any;
  durations: any;
  drug_interval: any;
  medication_id: string;
  medicine_name: string;
  medicine_description: string;
  instruction?: string;
  drugInterval: string;
  selectedDrugInterval: string;
  selectedFrequency: string;
  selectedDuration: [];
  chief_complaints: {
    chief_complaint_id: string;
    chief_complaint_name: string;
  }[];
  combinations: {
    0: {
      medication_duration_id: string;
      duration: string;
    };
    1: {
      frequency_id: string;
      morning: boolean;
      afternoon: boolean;
      evening: boolean;
    };
    combinationToDisplay: string;
  }[];
}

export const myProfileGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-profile",
  restMethod: RestMethod.get,
  authentication: true,
};

export interface DoctorProfileInterface {
  "provider_id": string,
  "cliniqally_provider_id": string,
  "first_name": string,
  "last_name": string,
  "profile_picture": null,
  "email": string,
  "country_code": string,
  "mobile": string,
  "dob": string,
  "gender": string,
  "address": string,
  "pincode": string,
  "default_clinic": string,
  "medical_council_reg_no": string,
  "registration_council_name": string,
  "awards": string,
  "about": string,
  "membership": string,
  "registration_year": string,
  "role_name": string,
  "clinic_name": string,
  "clinic_country_code": string,
  "clinic_phone_number": string,
  "clinic_country": string,
  "clinic_state": string,
  "clinic_city": string,
  "clinic_pincode": string,
  "account_holder_name": null,
  "account_number": null,
  "bank_branch": null,
  "ifsc_code": null,
  "bank_name": null,
  "signature_file": string,
  "degree": [],
  "specializations": [],
  "languages": []
}
export class CliniqallyUserAppUpdateCliniqallyProviderIdApiJson extends CliniqallyUserAppBaseRestApiJson {
  cliniqallyProviderId: string;
}

export const updateCliniqallyProviderIdJson: CliniqallyUserAppUpdateCliniqallyProviderIdApiJson = {
  url: "v1/service-provider/vitals/update-vital-signs",
  restMethod: RestMethod.post,
  authentication: true,
  cliniqallyProviderId: '',
};

export const getPanelListGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/panels/list-panels",
  restMethod: RestMethod.get,
  authentication: true,
};
export class CliniqallyUserAppCreatePaymentModeApiJson extends CliniqallyUserAppBaseRestApiJson {
  mode_name: string;
  mode_description: string;
  status: string;
  payment_mode_types: string;
  is_available_offline: string;
  is_available_online: string;
  account_holder_name?: string;
  account_number?: string;
  bank_branch?: string;
  bank_name?: string;
  ifsc_code?: string;
}

export const createPaymentModeJson: CliniqallyUserAppCreatePaymentModeApiJson = {
  url: "v1/service-provider/payment-mode/create-payment-mode",
  restMethod: RestMethod.post,
  authentication: true,
  mode_name: '',
  mode_description: '',
  status: 'Enabled',
  payment_mode_types: '',
  is_available_offline: '',
  is_available_online: '',
  account_holder_name: '',
  account_number: '',
  bank_branch: '',
  bank_name: '',
  ifsc_code: '',
};

export const paymentModeGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/payment-mode/list-payment-mode",
  restMethod: RestMethod.get,
  authentication: true,
};
export interface PanelListInterface {
  "panel_id": string,
  "panel_name": string,
  "panel_members": [
    {
      "user_type": string,
      "first_name": string,
      "last_name": string
    }
  ]
}
export interface PaymentModeGetListInterface {
  "payment_mode_id": string,
  "mode_name": string,
  "mode_description": string,
  "status": string,
  "payment_mode_types": string,
  "is_available_offline": string,
  "is_available_online": string,
  "bank_account"?: {
    "bankaccount_id"?: string,
    "account_holder_name"?: string,
    "account_number"?: string,
    "bank_branch"?: string,
    "bank_name"?: string,
    "ifsc_code"?: string
  }
}

export const paymentModeDetailGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/payment-mode/get-payment-mode-by-id",
  restMethod: RestMethod.get,
  authentication: true,
};

export const getProviderFaq: CliniqallyUserAppPostFAQRestApiJson = {
  url: "v1/service-provider/list-faq",
  restMethod: RestMethod.post,
  authentication: true,
  mobileAndroid: false,
  mobileIos: false,
  web: true
};
export class CliniqallyUserAppUpdatePaymentModeRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  status: string;
  is_available_offline: string;
  is_available_online: string;
}

export const updatePaymentModeJson: CliniqallyUserAppUpdatePaymentModeRestApiJson = {
  url: "v1/service-provider/payment-mode/update-payment-mode",
  restMethod: RestMethod.post,
  authentication: true,
  status: '',
  is_available_offline: '',
  is_available_online: '',
};

export interface FaqPostApiInterface {
  faq_id: string;
  faq_title: string;
  faq_body: string;
}

export function nullStringChecker(inputString: string) {
  return inputString == "null" ? '' : inputString;
}

export const deleteAppointmentJson: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/update-status-cancelled',
  restMethod: RestMethod.patch,
  authentication: true,
};

export class CliniqallyUserAppEndConsultationApiJson extends CliniqallyUserAppBaseRestApiJson {
  appointmentId: string;
  consultationId: string;
}

export const endConsultationJson: CliniqallyUserAppEndConsultationApiJson = {
  url: "v1/service-provider/consultation/update-consultation-ends",
  restMethod: RestMethod.post,
  authentication: true,
  appointmentId: '',
  consultationId: ''
};

export class CliniqallyUserAppGetSpecializationRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  status: boolean;
  degree_id: string;
}

export const getSpecializationAfterDegreeJson: CliniqallyUserAppGetSpecializationRestApiJson = {
  url: "v1/service-provider/get-specialization",
  restMethod: RestMethod.post,
  authentication: true,
  status: true,
  degree_id: '',
};

export class CliniqallyUserAppCreateMedicalCertificateApiJson extends CliniqallyUserAppBaseRestApiJson {
  consumer_id: string;
  appointment_id: string;
  consultation_id: any;
  start_date: any;
  end_date: any;
  medical_certificate_remarks: any;
}

export const createMedicalCertificateJson: CliniqallyUserAppCreateMedicalCertificateApiJson = {
  url: "v1/service-provider/create-medical-certificate",
  restMethod: RestMethod.post,
  authentication: true,
  consumer_id: '',
  appointment_id: '',
  consultation_id: '',
  start_date: '',
  end_date: '',
  medical_certificate_remarks: '',
};

export const allMedicalCertificateGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-medical-certificate",
  restMethod: RestMethod.get,
  authentication: true,
};

export const requestedMedicalCertificateGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-medical-certificate",
  restMethod: RestMethod.get,
  authentication: true,
};

export const completedMedicalCertificateGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-medical-certificate",
  restMethod: RestMethod.get,
  authentication: true,
};

export const rejectedMedicalCertificateGetJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-medical-certificate",
  restMethod: RestMethod.get,
  authentication: true,
};

export interface GetMedicalCertificateByStatusInterface {
  "prescription_id": string,
  "appointment_date": string,
  "appointment_id": string
  "user_details": {
    "first_name": string,
    "last_name": string,
    "mobile": string,
    "country_code": string,
    "profile_picture": string
  }
}

export const getMedicalCertificateJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-medical-certificate",
  restMethod: RestMethod.get,
  authentication: true,
};

export interface GetListMedicalCertificateInterface {
  "user_details": {
    "first_name": string,
    "last_name": string,
    "gender": string,
    "email": string,
    "mobile": string,
    "country_code": string,
    "profile_picture": string,
    "age": string
  },
  "medication_details": [
    {
      "medication_id": string,
      "medicine_name": string,
      "instruction_id": string,
      "instruction": string,
      "medicine_description": string,
      "duration": string,
      "frequency": {
        "morning": string,
        "afternoon": string,
        "evening": string
      },
      "_id": string
    }
  ],
  "consultation_data": {
    "diagnosis": string,
  },
  "consumer_id": string,
  "appointment_id": string,
  "consultation_id": string,
  "medical_certificate_status": string,
  "appointment_date": string,
  "patient_medical_certificate_remarks": string,
  "requested_end_date": string,
  "requested_start_date": string
}
export class CliniqallyUserAppApproveMedicalCertificateApiJson extends CliniqallyUserAppBaseRestApiJson {
  appointment_id: string;
  start_date: any;
  end_date: any;
  medical_certificate_remarks: any;
}

export const approveMedicalCertificateJson: CliniqallyUserAppApproveMedicalCertificateApiJson = {
  url: "v1/service-provider/approve-medical-certificate",
  restMethod: RestMethod.post,
  authentication: true,
  appointment_id: '',
  start_date: '',
  end_date: '',
  medical_certificate_remarks: '',
};

export class CliniqallyUserAppRejectMedicalCertificateApiJson extends CliniqallyUserAppBaseRestApiJson {
  appointment_id: string;
  rejection_remarks: any;
}

export const rejectMedicalCertificateJson: CliniqallyUserAppRejectMedicalCertificateApiJson = {
  url: "v1/service-provider/reject-medical-certificate",
  restMethod: RestMethod.post,
  authentication: true,
  appointment_id: '',
  rejection_remarks: '',
};

export const getInvoiceJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/invoice/get-invoice-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: '',
};

export interface invoiceType {
  first_name: string,
  invoice_id: string,
}
export const getInvoiceDetailsByInvoiceIdJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/invoice/get-invoice",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: '',
};

// create invoice class
export class CliniqallyUserAppCreateInvoiceApiJson extends CliniqallyUserAppBaseRestApiJson {
  invoice_id: string;
  discount_type: string;
  discount_value: string;
  paidAmount: string;
  discount_remarks: string;
  payment_mode: string;
  medicineDataArray: [
    {
      medicine_name: string;
      medicine_description: string;
      medicine_code: string;
      medicine_unit_price: string;
      tax_type_id: string;
    }
  ];
  customLineItemsArray: [
    {
      item_name: string;
      item_description: string;
      item_code: string;
      item_unit_price: string;
      tax_type_id: string;
    }
  ];
  saveAndSend: true;
}

export const createInvoiceJson: CliniqallyUserAppCreateInvoiceApiJson = {
  url: "v1/service-provider/invoice/create-invoice",
  restMethod: RestMethod.post,
  authentication: true,

  invoice_id: '',
  discount_type: '',
  discount_value: '',
  paidAmount: '',
  discount_remarks: '',
  payment_mode: '',
  medicineDataArray: [
    {
      medicine_name: '',
      medicine_description: '',
      medicine_code: '',
      medicine_unit_price: '',
      tax_type_id: '',
    }
  ],
  customLineItemsArray: [
    {
      item_name: '',
      item_description: '',
      item_code: '',
      item_unit_price: '',
      tax_type_id: '',
    }
  ],
  saveAndSend: true
};

export const getInvoicePaymentModeJson: CliniqallyUserAppBaseRestApiJson =
{
  url: "v1/service-provider/invoice/create-invoice-get-details",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: '',
};

export interface paymentModeType {
  payment_mode: [
    {
      payment_mode_id: string;
      mode_name: string;
    }
  ]
}

export interface GetInvoiceDataInterface {
  appointment_date: string,
  appointment_id: string,
  appointment_mode: string,
  appointment_time: string,
  center_id: string,
  clinic_details: {
    clinic_pan_no: string,
    clinic_gst_no: string
  },
  consultation_id: string,
  consumer_id: string,
  currency: {
    currency_id: string,
    currency: string,
    symbol: string,
    symbol_at: string
  }
  custom_line_items: [{
    item_code: string,
    item_description: string,
    item_id: string,
    item_name: string,
    item_price: number,
    item_price_value: number,
    item_tax: number,
    item_unit_price: string,
    tax_description: string,
    tax_name: string,
    tax_type_id: string,
    tax_value: string,
    id: string
  }],

  invoice_id: string,
  invoice_status: string,
  medicines: [{
    medicine_name: string,
    medicine_description: string,
    medicine_code: string,
    medicine_unit_price: string,
    tax_type_id: string,
  }],
  procedure_details: [],
  provider_id: string,
  subTotal: number,
  total: number,
  total_tax: number,
  user_details:
  {
    first_name: string,
    last_name: string
  }
  discount_type: string,
  discount_value: string,
  discount_remarks: string

}
export interface invoiceTaxType {
  tax: [
    {
      tax_id: string;
      tax_name: string;
      tax_value: string;
    }
  ]
}

export interface PaymentModeData {
  payment_mode: [
    {
      payment_mode_id: string;
      mode_name: string
    }
  ]
  tax: [
    {
      tax_id: string,
      tax_name: string
      tax_value: number
    }
  ]

}

export enum discountTypeEnum {
  Fixed = "Fixed",
  Percentage = "Percentage",
  None = "None"
}
export interface procedureType {
  procedure_id: string;
  procedure_name: string,
  procedure_code: string,
  panel_name: string,
  procedure_description: string,
  procedure_price: string,
  tax_value: string
}
export const deleteProcedureInvoice: CliniqallyUserAppBaseRestApiJson = {
  url: 'v1/service-provider/invoice/delete-medical-procedures',
  restMethod: RestMethod.post,
  authentication: true,
};

export class CliniqallyUserAppAddMedicalRecordsApiJson extends CliniqallyUserAppBaseRestApiJson {
  consumer_id: string;
  record_type: string;
  appointment_id: string;
  consultation_id: string;
}

export class CliniqallyUserAppPostClinicInfoApiJson extends CliniqallyUserAppBaseRestApiJson {
  unique_url: string;
}

export const addMedicalRecordsJson: CliniqallyUserAppAddMedicalRecordsApiJson = {
  url: "v1/service-provider/create-medical-records",
  restMethod: RestMethod.post,
  authentication: true,
  consumer_id: '',
  record_type: '',
  appointment_id: '',
  consultation_id: '',
  contentType: ContentType.formData,
  uploadFormData: new FormData()
};

export const getClinicDetailsClinicOverViewJson: CliniqallyUserAppPostClinicInfoApiJson = {
  url: "v1/clinic-info",
  restMethod: RestMethod.post,
  authentication: true,
  unique_url: "",
};

export const uploadServiceCenterProfilePicture: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/update-service-centre-profile-picture",
  restMethod: RestMethod.post,
  authentication: true,
  contentType: ContentType.formData,
  uploadFormData: new FormData()
};

export class CliniqallyListDependent extends CliniqallyUserAppBaseRestApiJson {
  mobile_number: string;
  cliniqally_consumer_id: string;
  country_code: string;
  provider_id: string;
  state_code: string;
  city_code: string;
}

export const listDependentJson: CliniqallyListDependent = {
  url: "v1/service-provider/dependent/list-dependent",
  restMethod: RestMethod.post,
  authentication: true,
  mobile_number: '',
  cliniqally_consumer_id: '',
  country_code: '',
  provider_id: '',
  state_code: '',
  city_code: '',
};

export const listDependentTypeJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/dependent/get-patient-details-prefill",
  restMethod: RestMethod.get,
  authentication: true,
};

export class CliniqallyAddNewDependent extends CliniqallyUserAppBaseRestApiJson {
  user_type: string;
  relation_type_id: string;
  pet_type_id: string;
  breed: string;
}

export const addDependentJson: CliniqallyAddNewDependent =
{
  url: "v1/service-provider/patient/create-patient",
  restMethod: RestMethod.post,
  authentication: true,
  user_type: '',
  relation_type_id: '',
  pet_type_id: '',
  breed: '',
};
export interface addDependentRelationType {
  "relation_type_list": [
    {
      relation_type: string,
      relation_type_id: string,
      status: string,
    }
  ]
}

export interface addDependentPetType {
  "pet_type_list": [
    {
      pet_type_name: string,
      pet_type_id: string,
      status: string,
    }
  ]
}

export enum dependentTypeEnum {
  relative = "relative",
  pet = "pet",
}

export const getMedicalPrescriptionJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/prescription/list-medical-prescriptions",
  restMethod: RestMethod.get,
  authentication: true,
};
export interface MedicalPrescriptionInterface {
  prescription_expiry_date: string;
  appointment_date: string;
  appointment_time: string;
  prescription_url: string;
}

export const getPrescriptionJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/prescription/list-prescriptions",
  restMethod: RestMethod.get,
  authentication: true,
};
export interface PrescriptionInterface {
  prescription_expiry_date: string;
  appointment_date: string;
  appointment_time: string;
  prescription_url: string;
}

export const getConsultationDataJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/get-consultation-data",
  restMethod: RestMethod.get,
  authentication: true,
};
export interface GetListConsultationDataInterface {
  user_details: {
    first_name: string,
    last_name: string,
    timezone: string,
    gender: string,
    email: string,
    mobile: string,
    country_code: string,
    cliniqally_consumer_id: string,
    profile_picture: string,
    age: string
  },
  medication_details: [
    {
      medication_id: string,
      medicine_name: string,
      instruction_id: string,
      instruction: string,
      medicine_description: string,
      duration: string,
      frequency: {
        morning: boolean,
        afternoon: boolean,
        evening: boolean
      },
      _id: string
    }
  ],
  consultation_data: {
    diagnosis: string
  },
  consumer_id: string,
  appointment_id: string,
  consultation_id: string,
  medical_certificate_status: string,
  appointment_date: string,
  appointment_time: string
}
export interface MedicineDataForMedicalCertificateInterface {
  selectedFrequency: string,
  selectedDuration: string,
  medication_id: string,
  medicine_name: string,
  instruction_id: string,
  instruction: string,
  medicine_description: string,
  duration: string,
  frequency: {
    morning: boolean,
    afternoon: boolean,
    evening: boolean
  },
  _id: string
}

export const postProcedureInvoice: CliniqallyUserAppBaseRestApiJson =
{
  url: "v1/service-provider/invoice/add-medical-procedures",
  restMethod: RestMethod.post,
  authentication: true,
};
export class CliniqallyUserAppListProviderStaffRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  providerId: string;
}

// edit patient get list
export const GetEditPatientJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/consumer/get-patient",
  restMethod: RestMethod.get,
  authentication: true,
};

// edit patient get consumer id
export interface getPatientIdForEditPatientInterface {
  appointmentId: string;
  providerId: string;
  patientName: string;
  countryCode: string;
  mobileNumber: string;
}

export class CliniqallyUserAppGetProvidersToAssignRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  provider: string;
}
export class CliniqallyUserAppUpdateMedicationApiJson extends CliniqallyUserAppBaseRestApiJson {
  medicationId: string;
  medicine_name: string;
  description: string;
  status: string;
  medicationDuration: [];
  medicationFrequency: { morning: boolean; afternoon: boolean; evening: boolean; }[];
  chiefComplaints: [];
  instruction: string;
}

export const updateMedicationJson: CliniqallyUserAppUpdateMedicationApiJson = {
  url: "v1/service-provider/medication/update-medication",
  restMethod: RestMethod.post,
  authentication: true,
  medicationId: '',
  medicine_name: '',
  description: '',
  status: '',
  medicationDuration: [],
  medicationFrequency: [],
  chiefComplaints: [],
  instruction: '',
};
export class CliniqallyUserAppAddDoctorUserExistRestApiJson extends CliniqallyUserAppBaseRestApiJson {
  providerId: string;
  designation: string;
  userType: string;
}

export const postAddDoctorExistDetailsJson: CliniqallyUserAppAddDoctorUserExistRestApiJson = {
  url: 'v1/service-provider/add-clinic-staff',
  restMethod: RestMethod.post,
  authentication: true,
  providerId: '',
  designation: '',
  userType: '',
};

export interface SpecializationType {
  id?: number;
  specialization_id?: string;
  specializationValue: string
  sub_specializations?: [
    {
      sub_specialization_id: string;
      subSpecializationValue: string
      experience: number;
    }
  ];
}

export interface SpecializationUpdated {
  experience?: number;
  specialization_id?: string;
  sub_specialization_id?: string;
}

// interface for list provider staff in assign staff feature
export interface ListProviderStaff {
  first_name: string,
  last_name: string,
  country_code: string,
  mobile: string,
  provider_id: string,
  cliniqally_provider_id: string,
  profile_picture: string,
  gender: string,
  verified: boolean,
  default_user_type: string,
  user_type: string,
  role_name: string
}

// interface for specialization list
export interface SpecializationListInterface {
  specialization_id: string,
  specialization: string
}

// create profile get list interface
export interface CreateProfileGetListInterface {
  registration_council_list: [
    {
      registration_council_id: string,
      registration_council_name: string
    },
  ],
  degree_list: [
    {
      degree_id: string,
      degree: string
    },
  ],
  language_list: [
    {
      lang_id: string,
      lang_name: string
    },
  ],
  country_list: [
    {
      country_id: string,
      name: string
    },
  ],
  timeZone_list: [
    {
      time_zone_id: string,
      time_zone: string,
      time_zone_name: string,
      time_zone_value: string
    },
  ],
  blood_groups: [
    {
      group_id: string,
      blood_group: string
    },
  ],
  marketing_channel_list: [
    {
      channel_id: string,
      channel_name: string
    },
  ]
}

export interface CreateProfileLanguageListInterface {
  lang_id: string,
  lang_name: string
}

export interface CreateProfileDegreeListInterface {
  degree_id: string,
  degree: string
}

export interface CreateProfileCouncilListInterface {
  registration_council_id: string,
  registration_council_name: string
}

export interface CreateProfileBloodGroupListInterface {
  group_id: string,
  blood_group: string
}

export interface CreateProfileMarketingChannelListInterface {
  channel_id: string,
  channel_name: string
}

export interface CreateProfileTimeZoneListInterface {
  time_zone_id: string,
  time_zone: string,
  time_zone_name: string,
  time_zone_value: string
}

export interface SubSpecializationListInterface {
  sub_specialization: string,
  sub_specialization_id: string,
}

// interface for get providers of admin
export interface GetProvidersOfAdminInterface {
  first_name: string,
  last_name: string,
  provider_id: string
}

// edit patient
export class CliniqallyUserAppEditPatientApiJson extends CliniqallyUserAppBaseRestApiJson {
  consumer_id: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  dob?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  emergency_contact_country_code?: string;
  emergency_contact?: string;
  blood_group?: string;
  height?: string;
  weight?: string;
  occupation?: string;
  blood_donation?: boolean;
  email?: string;
}
export const editPatientJson: CliniqallyUserAppEditPatientApiJson = {
  url: 'v1/service-provider/consumer/update-patient',
  restMethod: RestMethod.patch,
  authentication: true,
  consumer_id: '',
  firstName: '',
  lastName: '',
  gender: '',
  dob: '',
  address: '',
  country: '',
  state: '',
  city: '',
  emergency_contact_country_code: '',
  emergency_contact: '',
  blood_group: '',
  height: '',
  weight: '',
  occupation: '',
  blood_donation: false,
  email: ''
};

// interface for get edit patient details list
export interface EditPatientDetailsListInterface {
  consumer_id: string,
  user_type: string,
  country_code: string,
  first_name: string,
  last_name: string,
  cliniqally_consumer_id: string,
  mobile: string,
  email: string,
  status: string,
  gender: string,
  blood_donation: false,
  dob: string,
  address: string,
  country: string,
  state: string,
  city: string,
  emergency_contact: string,
  emergency_contact_country_code: string,
  occupation: string,
  blood_group: string,
  height: string,
  weight: string,
  parent_data: string,
  age: string
}

// create and update patient get list
export interface CreateAndUpdatePatientGetDetails {
  country_list: [
    {
      country_id: string,
      name: string
    },
  ],
  blood_groups: [
    {
      group_id: string,
      blood_group: string
    },
  ],
  occupation_list: [
    {
      occupation_id: string,
      occupation: string
    },
  ],
  timeZone_list: [
    {
      time_zone_id: string,
      time_zone: string,
      time_zone_name: string,
      time_zone_value: string
    },

  ],
  medicalProcedures_list: [],
  providerDetails: [
    {
      provider_id: string,
      user_type: string,
      first_name: string,
      last_name: string,
      default_user_type: string
    },
  ],
  relation_type_list: [
    {
      relation_type: string,
      relation_type_id: string,
      status: string
    },
  ],
  pet_type_list: [
    {
      pet_type_name: string,
      pet_type_id: string,
      status: string
    }
  ]
}

export enum appointmentInvoiceStatusEnum {
  generated = "generated",
  notStarted = "notStarted",
  active = "active"
}

// invoice get list
export const GetInvoiceListJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/invoice/list-invoices",
  restMethod: RestMethod.get,
  authentication: true,
};

// upload signature
export const PostServiceProviderSignatureJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/upload-signature-file",
  restMethod: RestMethod.post,
  authentication: true,
  contentType: ContentType.formData,
  uploadFormData: new FormData()
};

// invoice list interface
export interface InvoiceListInterface {
  invoice_id: string,
  provider_details: {
    first_name: string,
    last_name: string,
    specializations: [
      {
        specialization: string,
        sub_specialization: string,
        experience: string
      }
    ]
  },
  currency: {
    currency: string,
    symbol: string,
    symbol_at: string
  },
  user_details: {
    first_name: string,
    last_name: string,
    cliniqally_consumer_id: string
  },
  procedure_details: [],
  total: string,
  createdAt: string,
  payment_mode: {
    mode_name: string
  },
  invoice_number: string,
  invoice_url: string
}

// list medical record based on consumer id 
export const getMedicalRecordsJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/list-medical-records",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: '',
};

export interface MedicalRecordType {
  createdAt: string;
  medical_record: string;
  record_id: string;
  record_type: string;
  id: string;
}

export const getPrescriptionAllPatientPageJson: CliniqallyUserAppBaseRestApiJson = {
  url: "v1/service-provider/prescription/list-medical-prescriptions",
  restMethod: RestMethod.get,
  authentication: true,
  pathParameters: '',
};

export function findOverlappingSessions(appointmentSetting: appointmentSettings[]) {
  const sessionTypeDayMap = new Map();
  // Iterate through the data
  for (const item of appointmentSetting) {
    const { session_type, day, sessions } = item;
    for (const sessionTypeValue of session_type) {
      for (const dayValue of day) {
        // Generate a unique key based on sessionTypeValue and day
        const key = `${sessionTypeValue}-${dayValue}`;
        if (!sessionTypeDayMap.has(key)) {
          sessionTypeDayMap.set(key, []);
        }
        const sessionList = sessionTypeDayMap.get(key);
        for (const session of sessions) {
          const { start_time, end_time } = session;
          // Check for overlapping sessions within the same sessionTypeValue and day
          const overlap = sessionList.some((existingSession: any) => {
            const { start, end } = existingSession;
            return (
              (start_time < end && end_time > start) ||
              (start < end_time && end > start_time)
            );
          });
          if (overlap) {
            return {
              message: `Overlapping sessions found for session type ${sessionTypeValue} on day ${dayValue}. Please correct this and try again`,
              status: true
            }; // Return true if overlapping sessions are found
          }
          sessionList.push({ start: start_time, end: end_time });
        }
      }
    }
  }
  return { message: "No overlapping sessions found", status: false }; // Return false if no overlapping sessions are found
}