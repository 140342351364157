import * as moment from 'moment-timezone';

export function convertToTimeZoneWithFormat(dateTime: moment.Moment, timezone: string, format: string) {
  const toReturn = convertFromUTCToTimezone(dateTime, timezone);
  return toReturn.format(format);
}

export function convertFromUTCToTimezone(dateTime: moment.Moment, timezone: string) {
  return moment(dateTime, "UTC").tz(timezone);
}

export function convertFromTimezoneToTimezone(dateTime: moment.Moment, initialTimezone: string, targetTimezone: string) {
  return moment(dateTime, initialTimezone).tz(targetTimezone,false);
}

export function getBrowserTimezone() {
  return moment.tz.guess();
}

export function convertTimeForPublicPage(inputTime: any) {
  const inputTimeArray = inputTime.split(":");
  const newTime = moment().hour(inputTimeArray[0]).minute(inputTimeArray[1]).second(0);
  return convertFromUTCToTimezone(newTime, getBrowserTimezone()).format("h:mm A");
}
