import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './@core/guard/auth.guard';
import { ClinicoverviewComponent } from './clinicoverview/clinicoverview.component';


export const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'login',
    // canActivateChild: [AuthGuard],
    // canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: 'addStaff',
    loadChildren: () => import('./pages/add-staff/add-staff.module').then(m => m.AddStaffModule)
  },

  { path: 'clinic/:cityName/:clinicName', component: ClinicoverviewComponent },
];

const routerConfig: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload',
};

// if (!environment.production) {
//   routerConfig.enableTracing = true;
// }

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
