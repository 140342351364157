<div *ngIf="!loader">
  <section class="bg-new">
    <div class="container">
      <div class="container">
        <div class="px-0 py-5 p-md-5  rounded text-center">
          <img class="mt-5 rounded-circle mx-auto d-block imageWidth"
            [src]="checkIfImageExist(getClinicOverView?.profile_picture)" alt="Cliniqally">
          <h1 class="fs-1 fw-bold">{{clinicName}}</h1>
          <p class="fs-3"><span *ngFor="let speciality of clinicSpecialities; let i = index">
              {{speciality.name.trim()}}{{i === clinicSpecialities.length -1 ? '' : ',' }}
            </span></p>
          <p *ngIf="clinicAverageRating>=1">
            <i class="pi pi-star-fill golden-color" *ngIf="clinicAverageRating>=1"></i>
            <i class="pi pi-star-fill golden-color" *ngIf="clinicAverageRating>=2"></i>
            <i class="pi pi-star-fill golden-color" *ngIf="clinicAverageRating>=3"></i>
            <i class="pi pi-star-fill golden-color" *ngIf="clinicAverageRating>=4"></i>
            <i class="pi pi-star-fill golden-color me-2" *ngIf="clinicAverageRating>=5"></i>
            {{clinicAverageRating}} Star
          </p>
          <p *ngIf="clinicAverageRating===0">No reviews or ratings</p>
          <a [href]="googleMapsUrl" target="_blank">
            <button pButton label="Get Directions" icon="pi pi-map-marker" type="button"
              class="p-button-raised p-button-rounded p-button-success bg-pri m-3"></button>
          </a>
          <a [href]="downloadUrl" target="_blank">
            <button pButton label="Contact Clinic" icon="pi pi-phone" type="button"
              class="p-button-raised p-button-rounded bg-blue m-3"></button>
          </a>
        </div>
        <!-- <div *ngIf="showDiv" class="col-md-3 tool-tip-cl">
        <div class="card p-4 text-center shadow-lg p-3 mb-5 bg-body rounded">
          <div>
            <img class="mb-2" src="assets/images/phone-book.png" alt="Cliniqally">
            <h4>Contact Details</h4>
            <p class="mb-2">To Change the registered Contact Details you need to contact the Help
              Support
            </p>
            <h6 class="mb-2"><i class="pi pi-phone cliniqallyBlueColor cursorPointer me-1"></i>+91 9620
              7891
              80</h6>
            <h6 class="mb-2">Contact Support Team<br>growth@cliniqally.com</h6>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </section>
  <section class="bg-new-2 ">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h3 class="fs-2 fw-bold b-c mb-6 mt-5">About {{clinicName}}</h3>
          <p class="mt-2 h6 lh-2">{{aboutClinic}}</p>
        </div>
        <div class="col-md-6">
          <div class="p-5">
            <img src="/assets/images/about.png" class="img-fluid p-3" alt="Cliniqally">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="">
    <div class="container mt-5">
      <div class="row mt-5">
        <div class="col-md-12 mt-4 p-3">
          <div class="card light-blue border-thin">
            <div class="card-body">
              <h3 class="card-header-new mt-4">Working Hours</h3>
              <div class="table-responsive">
                <table class="table booking">
                  <tbody>
                    <tr *ngFor="let day of days">
                      <td>{{day.day}}</td>
                      <td class="text-center" *ngFor="let timing of day.timing">{{timing.start_time}} -
                        {{timing.end_time}}</td>
                      <td class="text-center">
                        <a [href]="downloadUrl" target="_blank" class="btn">
                          Book Now
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- to do : will be implemented later -->
              <!-- <p class="text-light h5 lh-lg">Time’s not Flexible?<br>
              We provide high quality, integrated healthcare services, based on a patient – centered</p>
            <div class="d-grid gap-2 col-6 mx-auto">
              <button pButton type="button" label="Suggest Time"
                class="p-button-raised p-button-rounded w-button"></button>
            </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4 p-3">
          <div class="card light-blue border-thin bg-green1">
            <div class="card-body">
              <h3 class="card-header-new mt-4">Address</h3>
              <table class="table booking">
                <tbody>
                  <tr class="bb-0">
                    <td class="bb-0 ps-2 pe-2"> {{clinicAddress}}</td>
                  </tr>
                  <tr>
                    <td class="bb-0 ps-2 pe-2">City: {{clinicCity}}</td>
                  </tr>
                  <tr>
                    <td class="bb-0 ps-2 pe-2">State: {{clinicState}}</td>
                  </tr>
                  <tr>
                    <td class="bb-0 ps-2 pe-2">Country: {{clinicCountry}}</td>
                  </tr>
                  <tr>
                    <td class="ps-2 pe-2">Pincode: {{clinicPincode}}</td>
                  </tr>
                </tbody>
              </table>
              <!-- <p class="text-light h5 lh-lg text-center mt-5">
              <i class="pi pi-youtube ico-size-soc"></i>
              <i class="pi pi-linkedin ico-size-soc"></i>
              <i class="pi pi-facebook ico-size-soc"></i>
              <i class="pi pi-whatsapp ico-size-soc"></i>
              <i class="pi pi-instagram ico-size-soc"></i>
            </p> -->
              <div id="locationDiv" class="col-12 mb-5 text-center">
                <span>
                  <a [href]="googleMapsUrl" class="m-2 btn" target="_blank">
                    Get Directions
                  </a>
                </span>
                <span>
                  <div class="mapouter mt-3">
                    <google-map *ngIf="locationLoaded" height="400px" width="100%" [zoom]="11" [center]="mapCenter"
                      [options]="mapOptions">
                      <map-marker #markerElem *ngFor="let marker of markers" [position]="marker.position"
                        [label]="marker.label" [title]="marker.title" [options]="marker.options"></map-marker>
                    </google-map>
                    <nb-alert status="danger" *ngIf="!locationLoaded">
                      Please allow location access to enable maps
                    </nb-alert>
                    <button class="btn m-1 updateButton" (click)="handlePermission()" type="button"
                      *ngIf="!locationLoaded">
                      Allow access
                    </button>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container mt-5">
      <h3 class="fs-2 fw-bold b-c mb-6">Doctors in Clinic</h3>
      <div class="row  mb-3">
        <div class="col-md-4" *ngFor="let doctorsData of DoctorDataList">
          <div class="card text-center bg-card-blue">
            <div>
              <img [src]="checkIfImageExist(doctorsData.profile_picture)" class="card-img-top p-3 pb-0" alt="...">
            </div>
            <div class="card-body d-flex flex-column justify-content-between">
              <h5 class="card-title">Dr. {{doctorsData.first_name}} {{doctorsData.last_name}}</h5>
              <p class="card-text" *ngFor="let degree of doctorsData.degree">{{degree}}</p>
              <p><a [href]="downloadUrl" target="_blank" class="btn mx-auto">Book Appointment</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-new-4 ">
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-4 mt-5">
          <h3 class="fs-2 fw-bold b-c mt-4 mb-6">Our Services</h3>
          <p class="l-h-p">{{aboutClinic}}</p>
        </div>
        <div class="col-md-8 ">
          <div class="row mt-5">
            <div class="col-md-4" *ngFor="let speciality of clinicSpecialities; let i = index">
              <div class="h-100 text-center">
                <img [src]="checkIfImageExist(speciality?.image)" class="small-ico" alt="Cliniqally">
                <div class="card-body d-flex flex-column justify-content-between">
                  <h5 class="card-title">{{speciality.name}}</h5>
                  <div class="p-3 pt-0">
                    <p class="mt-2 grid-text">{{speciality.description}}</p>
                  </div>
                  <p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
  </section>
  <!-- <section class="">
  <div class="container">
    <h3 class="fs-2 fw-bold b-c mt-4 mb-6 text-center">Our Testimonials</h3>
    <div id="text-carousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner mb-2">
        <div class="carousel-item active">
          <div class="row mt-5">
            <div class="col-md-4 col-sm-12  mt-2">
              <div class="carousel-box m-2 text-center">
                <img src="/assets/images/quote.png" class="small-ico qte" alt="...">
                <p class="l-h-p">
                  i don't normally talk to doctors online. this is the first time. and i have to say it was a good
                  experience. doctor responded in about 7-8 minutes. first asked many questions i don't normally talk to
                  doctors online. this is the first time. and i have to say it was a good experience. doctor responded
                  in
                  about 7-8 minutes. first asked many questions i don't normally talk to doctors online.</p>
                <h1 class="card-title text-center rounded-circle b-green">A</h1>
                <h1 class="card-title text-center">Arjun Das</h1>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 mt-2">
              <div class="carousel-box m-2">
                <img src="/assets/images/quote.png" class="small-ico qte" alt="...">
                <p class="l-h-p">
                  i don't normally talk to doctors online. this is the first time. and i have to say it was a good
                  experience. doctor responded in about 7-8 minutes. first asked many questions i don't normally talk to
                  doctors online. this is the first time. and i have to say it was a good experience. doctor responded
                  in
                  about 7-8 minutes. first asked many questions i don't normally talk to doctors online.</p>
                <h1 class="card-title text-center rounded-circle b-green">A</h1>
                <h1 class="card-title text-center">Arjun Das</h1>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 mt-2">
              <div class="carousel-box m-2">
                <img src="/assets/images/quote.png" class="small-ico qte" alt="...">
                <p class="l-h-p">
                  i don't normally talk to doctors online. this is the first time. and i have to say it was a good
                  experience. doctor responded in about 7-8 minutes. first asked many questions i don't normally talk to
                  doctors online. this is the first time. and i have to say it was a good experience. doctor responded
                  in
                  about 7-8 minutes. first asked many questions i don't normally talk to doctors online.</p>
                <h1 class="card-title text-center rounded-circle b-green">A</h1>
                <h1 class="card-title text-center">Arjun Das</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row mt-5">
            <div class="col-md-4  col-sm-12 mt-2">
              <div class="carousel-box m-2 mb-4">
                <img src="/assets/images/quote.png" class="small-ico qte" alt="...">
                <p class="l-h-p">
                  i don't normally talk to doctors online. this is the first time. and i have to say it was a good
                  experience. doctor responded in about 7-8 minutes. first asked many questions i don't normally talk to
                  doctors online. this is the first time. and i have to say it was a good experience. doctor responded
                  in
                  about 7-8 minutes. first asked many questions i don't normally talk to doctors online.</p>
                <h1 class="card-title text-center rounded-circle b-green">A</h1>
                <h1 class="card-title text-center">Arjun Das</h1>
              </div>
            </div>
            <div class="col-md-4  col-sm-12 mt-2">
              <div class="carousel-box m-2">
                <img src="/assets/images/quote.png" class="small-ico qte" alt="...">
                <p class="l-h-p">
                  i don't normally talk to doctors online. this is the first time. and i have to say it was a good
                  experience. doctor responded in about 7-8 minutes. first asked many questions i don't normally talk to
                  doctors online. this is the first time. and i have to say it was a good experience. doctor responded
                  in
                  about 7-8 minutes. first asked many questions i don't normally talk to doctors online.</p>
                <h1 class="card-title text-center rounded-circle b-green">A</h1>
                <h1 class="card-title text-center">Arjun Das</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#text-carousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#text-carousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</section> -->
  <section class="mt-5">
    <div class="container mt-5 mb-5">
      <h3 class="fs-2 fw-bold b-c mt-4 mb-6 text-center mb-4">Gallery</h3>
      <div class="row row-cols-3 g-3">
        <div *ngIf="getClinicOverView?.clinic_images?.length>0">
          <div class="col-12 col-md-4" *ngFor="let clinicImage of getClinicOverView?.clinic_images">
            <a [href]="checkIfImageExist(clinicImage.imgPath)" target="_blank" class="text-pp-crt">
              <img [src]="checkIfImageExist(clinicImage.imgPath)" class="img-fluid" alt="Cliniqally">
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="getClinicOverView?.clinic_images?.length<=0">
        <div class="col-12">
          <h5 class="card-title text-center">No Images available</h5>
        </div>
      </div>
    </div>
  </section>
  <!-- to do : will be implemented later -->
  <!-- <section class="counter-area text-center mt-4">
  <div class="container">
      <div class="row">
          <div class="col-md-4">
              <div class="counter-box">
                  <img src="../assets/icon/traumatism.svg" alt="" class="img-fluid">
                  <p class="counter">500+</p>
                  <h6>Patients</h6>
              </div>
          </div>
          <div class="col-md-4">
              <div class="counter-box">
                  <img src="../assets/icon/doctor_male1.svg" alt="" class="img-fluid">
                  <p class="counter">20+</p>
                  <h6>Doctors</h6>
              </div>
          </div>
          <div class="col-md-4">
              <div class="counter-box">
                  <img src="../assets/icon/clinical_fe.svg" alt="" class="img-fluid">
                  <p class="counter">5.5k+</p>
                  <h6>Bookings</h6>
              </div>
          </div>
      </div>
  </div>
</section> -->
  <div class="container-fluid mt-2">
    <div class="row ft-copyright p-3 mt-1">
      <div class="col-sm-4 text-pp-crt my-auto">
        <a href="https://cliniqally.com" target="_blank" class="text-pp-crt">
          ©{{currentYear}} Powered by Cliniqally. All rights reserved
        </a>
      </div>
      <div class="col-sm-2 text-pp-crt-rg my-auto">
        <a href="https://cliniqally.com/privacypolicy" target="_blank" class="text-pp-crt">
          Privacy Policy
        </a>
      </div>
      <div class="col-sm-2 developer my-auto">
        <a href="https://cliniqally.com/termsandconditions" target="_blank" class="text-pp-crt">
          Terms & Conditions
        </a>
      </div>
      <div class="col-sm-2 text-pp-crt-rg my-auto"></div>
      <div class="col-sm-2 text-pp-crt-rg my-auto">
        <ul class="list-unstyled list-inline social m-0">
          <li class="list-inline-item">
            <a href="https://www.youtube.com/@cliniqallyhealthtechpvtltd4693" target="_blank">
              <i class="pi pi-youtube m-1"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://instagram.com/cliniqally">
              <i class="pi pi-linkedin m-1" target="_blank"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.facebook.com/cliniqally?mibextid=ZbWKwL" target="_blank">
              <i class="pi pi-facebook m-1">
              </i>
            </a>
          </li>
          <!-- <li class="list-inline-item"><a href=""><i class="pi pi-whatsapp m-1"></i></a></li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container bg-div d-flex justify-content-center" *ngIf="loader">
  <div class="loading loading07 align-self-center">
    <span data-text="C">C</span>
    <span data-text="L">L</span>
    <span data-text="I">I</span>
    <span data-text="N">N</span>
    <span data-text="I">I</span>
    <span data-text="Q">Q</span>
    <span data-text="A">A</span>
    <span data-text="L">L</span>
    <span data-text="L">L</span>
    <span data-text="Y">Y</span>
    <div *ngIf="showNotFound">
      <h2 class="fs-2 fw-bold b-c mt-4 mb-6">
        This clinic is not found in cliniqally
        <br> Please retry with a valid link
      </h2>
    </div>
  </div>
</div>