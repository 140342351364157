export enum profileCompletionStatusEnum {
    DoctorProfile,
    CreateClinic,
    UploadDocument,
    SwitchClinic,
    Finished
}

// export enum profileType{
//     Doctor,
//     Staff
// }
// export enum 